<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetDefenseTroops from '@/components/Sections/Planets/PlanetTabs/DefenceTab/PlanetDefenseTroops.vue'
import PlanetDefensePlanetaryShields
  from '@/components/Sections/Planets/PlanetTabs/DefenceTab/PlanetDefensePlanetaryShields.vue'
import PlanetDefenseActive from '@/components/Sections/Planets/PlanetTabs/DefenceTab/PlanetDefenseActive.vue'
import PlanetDefensePassive from '@/components/Sections/Planets/PlanetTabs/DefenceTab/PlanetDefensePassive.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const troops = computed(() => {

  // Calculate the percentage of the planet's hitpoints
  const percentage = Math.round((planetData.value.currentHitPoints / planetData.value.maxHitPoints) * 100);

  let color = 'text-green-500';
  if(percentage < 50 && percentage > 25) {
    color = 'text-yellow-500';
  } else if(percentage <= 25) {
    color = 'text-red-500';
  }

  //Get the number of troops icons, one for each 100 hitpoints
  const troopsIcons = Math.floor(planetData.value.currentHitPoints / 100);

  //Get the number of pending troops icons, one for each 100 hitpoints
  const totalTroopsIcons = Math.floor(planetData.value.maxHitPoints / 100);
  const pendingTroopsIcons = totalTroopsIcons - troopsIcons;

  return {
    troopsIcons: troopsIcons,
    pendingTroopsIcons: pendingTroopsIcons,
    percentage: percentage,
    color: color
  }
})


</script>

<template>
  <div class="flex flex-col xl:flex-row">
    <div class="flex-col xl:w-1/2">
      <PlanetDefenseTroops :planetId="props.planetId"/>
      <PlanetDefenseActive :planetId="props.planetId"/>
    </div>
    <div class="flex-col xl:w-1/2 ps-0 xl:ps-2">
      <PlanetDefensePlanetaryShields :planetId="props.planetId"/>
      <PlanetDefensePassive :planetId="props.planetId"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
