<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed, defineProps, ref, type Ref, watch } from 'vue'
import type ProductionObject from '@/types/ProductionObject.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import PlanetProductionAvailableProductionObject
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Elements/PlanetProductionAvailableProductionObject.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore();
const gameStore = useGameStore();
const assetStore = useAssetStore();

/**
 * Variables
 */
const availableProductionObjects: Ref<ProductionObject[]> = ref([]);

/**
 * Computed
 */
const planetData = computed(() => {
  const planet = planetStore.findPlanetById(props.planetId);
  if (!planet) {
    throw new Error(`Planet with ID ${props.planetId} not found.`);
  }
  return planet;
});

/**
 * Functions
 */

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {
  const productionObjectsMap = gameStore.playerProductionObjects;

  // If no production objects exist, reset the list and return
  if (!productionObjectsMap || productionObjectsMap.size === 0) {
    availableProductionObjects.value = [];
    return;
  }

  // Convert the map into an array of ProductionObjects, and sort by productionCost
  let tempProductionObjects = Array.from(productionObjectsMap.entries())
    .map(([id]) => {
      const productionObject = assetStore.findProductionObjectById(id);
      if (!productionObject) {
        // If your app logic should never allow a missing object, you can throw.
        // Or handle gracefully by ignoring / filtering out the missing ones:
        throw new Error(`Production object with ID ${id} not found.`);
      }
      return productionObject;
    })
    .sort((a, b) => a.productionCost - b.productionCost);

  const {
    buildings = [],
    productionObjectId,
    productionQueue = []
  } = planetData.value;

  // Filter out buildings that have already been constructed
  tempProductionObjects = tempProductionObjects.filter((prod) => {
    return !(prod.type === "building" && buildings.includes(prod.objectId));
  });

  // Filter out the building currently in production
  if (productionObjectId) {
    tempProductionObjects = tempProductionObjects.filter(
      (prod) => !(prod.type === "building" && prod.id === productionObjectId)
    );
  }

  // Filter out any buildings in the queue
  tempProductionObjects = tempProductionObjects.filter((prod) => {
    if (prod.type !== "building") return true;
    return !productionQueue.some(
      (queueItem) => queueItem.productionObjectId === prod.id
    );
  });

  // Filter by the selected menu type if not 'all'
  const { planetProductionMenuType } = planetStore;
  if (planetProductionMenuType !== "all") {
    tempProductionObjects = tempProductionObjects.filter((prod) => {
      // Buildings
      if (prod.type === "building") {
        const building = assetStore.findBuildingByCode(prod.objectId);
        // Keep if it has at least one matching effect with a positive bonus
        return building.effects.some(
          (effect:{type:string,bonus:number}) =>
            effect.type === planetProductionMenuType && effect.bonus > 0
        );
      }
      // Ships
      return planetProductionMenuType === "ship";
    });
  }

  availableProductionObjects.value = tempProductionObjects;
};


/**
 * Watchers
 */
// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well
watch(() => planetStore.planetProductionMenuType, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well



</script>

<template>
  <div class="flex-grow pt-1">
    <template v-for="productionObject in availableProductionObjects" :key="productionObject.id">
      <PlanetProductionAvailableProductionObject :production-object-id="productionObject.id" :planet-id="planetId"/>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>