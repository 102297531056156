<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { computed } from 'vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import MediumPlusModel from '@/components/Interface/Modal/ModelSizes/MediumPlusModel.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

/**
 * Stores / models
 */
const gameStore = useGameStore()
const playerRelationStore = usePlayerRelationStore()
const backend = new Backend();

/**
 * Functions
 */
const declareWar = () => {

  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to declare war?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      backend.relationDeclareWar(gameStore.modalData.relation.playerId).then(() => {

      })
    },
  };



}

const requestOrAccept = (type: string) => {
  backend.relationRequest(gameStore.modalData.relation.playerId, type).then(() => {

  })
}

const rejectRequest = (type: string) => {
  backend.relationReject(gameStore.modalData.relation.playerId, type).then(() => {

  })
}

const stopNapRenewal = () => {

  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Discontinuing the non-aggression pact will cause it to automatically expire in 300 days. The other player will not be notified until the pact expires. Are you sure you want to stop its renewal?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      backend.stopNAPRenewal(gameStore.modalData.relation.playerId).then(() => {
      })
    },
  };
}

/**
 * Computed
 */
const relation = computed(() => {
  return playerRelationStore.findRelationByPlayerId(gameStore.modalData.relation.playerId)
})

</script>

<template>

  <MediumPlusModel :title="relation.name">
    <div class="p-3 md:p-3">
      <div class="flex">
        <div class="hidden sm:block">
          <img :src="relation.profileImage" class="rounded" style="width: 40rem;" />
        </div>
        <div>
          <div class="ms-2 sm:ms-6">
            <ol class="relative border-s border-slate-700 text-slate-300">
              <!-- Enemy -->
              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded -start-2 ring-4 ring-slate-900': true,
                  'bg-slate-800': relation.relation !== 'enemy',
                  'bg-red-800': relation.relation === 'enemy'
                }">
                </span>
                <h3 class="font-medium leading-tight text-slate-300">Enemy</h3>
                <div class="rounded p-1 mt-2 mb-2 text-subtext">
                  Being at war allows you to attack the enemy and take their resources. You can also be attacked by the enemy.
                </div>
                <div v-if="['enemy'].includes(relation.relation)" class="pb-2">
                  <div v-if="relation.iRequested === 'peace'" class="p-2 bg-slate-800 rounded">
                    Request for peace has been sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'peace'">
                    <div class="p-2 bg-slate-800 rounded">
                      <div class="pb-2">{{ relation.name }} has offered peace. Do you accept?</div>
                      <ActionButton @click.once="requestOrAccept('peace')">Accept</ActionButton>
                      <DeleteButton color="red" @click.once="rejectRequest('peace')">Reject</DeleteButton>
                    </div>
                  </div>
                  <div v-else>
                    <DeleteButton @click.once="requestOrAccept('peace')">Request peace</DeleteButton>
                  </div>
                </div>
              </li>
              <!-- Neutral -->
              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-slate-900': true,
                  'bg-slate-800': relation.relation !== 'neutral',
                  'bg-amber-900': relation.relation === 'neutral'
                }">
                </span>
                <h3 class="font-medium leading-tight text-slate-300">Neutral</h3>
                <div class="rounded p-1 mt-2 mb-2 text-subtext">
                  Neutral relations are the default state of all players. It has no benefits or drawbacks.
                </div>
                <div v-if="['unknown', 'neutral'].includes(relation.relation)" class="pb-2">
                  <div v-if="relation.iRequested === 'friend'" class="p-2 bg-slate-800 rounded">
                    Friendship request was sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'friend'">
                    <div class="p-2 bg-slate-800 rounded">
                      <div class="pb-2">{{ relation.name }} wants to become friends. Do you accept?</div>
                      <ActionButton @click.once="requestOrAccept('friend')">Accept</ActionButton>
                      <DeleteButton color="red" @click.once="rejectRequest('friend')">Reject</DeleteButton>
                    </div>
                  </div>
                  <div v-else>
                    <ActionButton @click.once="requestOrAccept('friend')">Send friendship request</ActionButton>
                  </div>
                  <div v-if="['friend', 'unknown', 'neutral'].includes(relation.relation)" class="pt-2">
                    <DeleteButton color="red" @click.once="declareWar">Declare WAR!</DeleteButton>
                  </div>
                </div>
              </li>
              <!-- Friend -->
              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-slate-900': true,
                  'bg-slate-800': relation.relation !== 'friend',
                  'bg-purple-800': relation.relation === 'friend'
                }">
                </span>
                <h3 class="font-medium leading-tight text-slate-300">Friend</h3>
                <div class="rounded p-1 mt-2 mb-2  text-subtext">
                  Friends are players who have agreed to help each other in times of need. Breaking a friendship can have consequences.
                </div>
                <div v-if="['friend'].includes(relation.relation)" class="pb-2 rounded">
                  <div v-if="relation.iRequested === 'nap'" class="p-2 bg-slate-800 rounded">
                    Non aggression pact request has been sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'nap'">
                    <div class="p-2 bg-slate-800 rounded">
                      <div class="pb-2">{{ relation.name }} has offered a non aggression pact. Do you accept?</div>
                      <div class="flex justify-evenly">
                        <ActionButton @click.once="requestOrAccept('nap')">Accept</ActionButton>
                        <DeleteButton color="red" @click.once="rejectRequest('nap')">Reject</DeleteButton>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <ActionButton @click.once="requestOrAccept('nap')">Request Non aggression pact</ActionButton>
                  </div>
                  <div v-if="['friend', 'unknown', 'neutral'].includes(relation.relation)" class="pt-2">
                    <DeleteButton color="red" @click="declareWar">Declare WAR!</DeleteButton>
                  </div>
                </div>
              </li>
              <!-- Ally -->
              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-slate-900': true,
                  'bg-slate-800': relation.relation !== 'ally',
                  'bg-cyan-800': relation.relation === 'ally'
                }">
                </span>
                <h3 class="font-medium leading-tight text-slate-300">Ally</h3>
                <div class="rounded p-1 mt-2 mb-2 text-subtext ">
                  Allied players have a Non aggression pact for 300 days. They cannot attack each other. Also basic information about the ally is shared.
                </div>
                <div v-if="['ally'].includes(relation.relation)" class="p-2 mb-2 bg-slate-800 rounded">
                  <div v-if="relation.stopNap === 'stop'">The non aggression pact will <span class="text-red-500">expire</span> in {{ relation.napExpireTurn - gameStore.turn }} days.</div>
                  <div v-else>
                    <div class="pb-2">The non aggression pact will <span class="text-green-500">auto renew</span> in {{ relation.napExpireTurn - gameStore.turn }} days.</div>
                    <DeleteButton color="red" @click="stopNapRenewal">Discontinue</DeleteButton>
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  </MediumPlusModel>

</template>

<style scoped lang="scss">

</style>