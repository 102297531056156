<script setup lang="ts">

import { formatShort } from '@/models/formatNumber.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()
const playerOreStore = usePlayerOreStore()

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const ore = computed(() => {
  return assetStore.findOreById(props.oreId)
})

const amount = computed(() => {
  return playerOreStore.data[ore.value.code]
})

const onClick = () => {
  gameStore.modalType = 'oreDetails'
  gameStore.modalData = {
    oreId: props.oreId
  }
}

</script>

<template>
  <div :class="{
    'absolute ': true,
    'border' : debug
  }" :style="'top:' + top + 'rem;left:' + left + 'rem;width:5rem;z-index:14;'">
    <div class="text-center flex-col" :style="'width:5rem;'">
      <img alt="" :class="{
            'ms-2':true,
            'opacity-50':debug
          }" style="width:4rem;" :src="gameStore.cdn + '/images/icons/ore/' + oreId + '.png'">
      <div  class="bg-slate-800 border rounded border-slate-700 hover:border-slate-600 cursor-pointer" @click="onClick">
        <div>{{ ore.oreName }}</div>
        <span class="text-subtext text-lg">
          <span v-if="amount" class="text-green-500">{{ formatShort(amount) }}</span>
          <span v-else>-</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>