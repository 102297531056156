<script setup lang="ts">

import { useFleetStore } from '@/stores/fleetStore.ts'
import router from '@/router'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import Position from '@/components/Interface/Position.vue'
import Percentage from '@/components/Interface/Text/Percentage.vue'
import type Fleet from '@/types/Fleet.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

const fleetStore = useFleetStore()
const shipStore = useShipStore()
const tableStore = useTableStore()

//Load filtered fleet list
const filters = (fleets:Map<number,Fleet>) => {
  return Array.from(fleets.values())
    .filter((fleet) => {
      // If no search query, return all
      if (!tableStore.fleets.filters.nameSearch || tableStore.fleets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the name (case-insensitive search)
      return fleet.name.toLowerCase().includes(tableStore.fleets.filters.nameSearch.toLowerCase());
    });
}

const loadItem = (id:number) => {
  router.push('/command/fleets/' + id);
}

</script>

<template>
  <FerionDataTable
    tableStoreName="fleets"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-9.5rem)]'"
    :data="fleetStore.fleets"
    :click="loadItem"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center rounded">
        <ModalTrigger type="createFleet" class="">
          <ActionButton class="my-1">
            Create
          </ActionButton>
        </ModalTrigger>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1 rounded"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.fleets.filters.nameSearch" name="Name" placeHolder="Search..." class=""/>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="12" column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="pl-2 rounded-tl">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthText width="3" align="center"  column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="hit_points"></GenericIcon>
      </HeaderFixedWidthText>
      <HeaderFixedWidthTextNoSort width="5" align="center">
        Core Fleet
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="6" align="center">
        Reinforce.
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthText width="5" column="xPos" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Location
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Action
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="3" column="etaNextHop" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center" class="rounded-tr">
        ETA
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="12" class="pl-2">
        <div class="flex-col">
          <div>{{ item.name }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="3" align="center">
        <Percentage :value="item.healthPercentage"></Percentage>
      </CellFixedWidth>
      <CellFixedWidth width="5" align="center">
        {{ shipStore.countShipsInFleet(item.id) }}
      </CellFixedWidth>
      <CellFixedWidth width="6" align="center">
        {{ shipStore.countShipsInFleetCatchingUp(item.id) }}
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <Position v-if="item.xPos !=0 && item.yPos !=0" :x="item.xPos" :y="item.yPos" @click.stop></Position>
        <span v-else>N/A</span>
      </CellFixedWidth>
      <CellFixedWidth width="10" align="center">
        {{ item.currentActionName }}
        {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.etaNextHop }}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>