<script setup lang="ts">

import MediumPlusModel from '@/components/Interface/Modal/ModelSizes/MediumPlusModel.vue'

import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import ModalCreateShipDesignDetails
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/ModalCreateShipDesignDetails.vue'
import ModalCreateShipDesignHelpTab
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/ModalCreateShipDesignHelpTab.vue'
import ModalCreateShipDesignAdd
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/ModalCreateShipDesignAdd.vue'
import ModalCreateShipDesignEdit
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/ModalCreateShipDesignEdit.vue'

/**
 * Stores / models
 */
const shipDesignCreationStore = useShipDesignCreationStore()

</script>

<template>
  <MediumPlusModel title="Create New Ship Design">
    <div class="p-2">
      <!-- Desktop --->
      <div class="hidden md:block">
        <ModalCreateShipDesignDetails v-if="shipDesignCreationStore.createModalTab!=='help'"/>
        <ModalCreateShipDesignHelpTab v-if="shipDesignCreationStore.createModalTab==='help'"/>
        <div v-else class="grid grid-cols-2 gap-2 pt-2">
          <div>
            <ModalCreateShipDesignEdit/>
          </div>
          <div>
            <ModalCreateShipDesignAdd/>
          </div>
        </div>
      </div>
      <!-- Mobile --->
      <div class="block md:hidden">
        <ModalCreateShipDesignDetails v-if="shipDesignCreationStore.createModalTab!=='help'"/>
        <div class="text-sm font-medium text-center border-b text-slate-400 border-slate-700 sm:hidden">
          <div class="overflow-x-auto whitespace-nowrap no-scrollbar">
            <ul class="flex">
              <li class="me-2">
                <a href="#"
                   :class="{
                     'active': shipDesignCreationStore.createModalTab === 'add',
                     'text-blue-500 border-blue-500': shipDesignCreationStore.createModalTab === 'add',
                     'text-slate-500 border-transparent': shipDesignCreationStore.createModalTab !== 'add',
                     'inline-block p-4 border-b-2 rounded-t-lg  hover:border-slate-300 hover:text-slate-300': true
                 }"
                   @click="shipDesignCreationStore.createModalTab = 'add'">
                  Add
                </a>
              </li>
              <li class="me-2">
                <a href="#"
                   :class="{
                     'active': shipDesignCreationStore.createModalTab === 'edit',
                     'text-blue-500 border-blue-500': shipDesignCreationStore.createModalTab === 'edit',
                     'text-slate-500 border-transparent': shipDesignCreationStore.createModalTab !== 'edit',
                     'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
                 }"
                   @click="shipDesignCreationStore.createModalTab = 'edit'">
                  Edit
                </a>
              </li>
              <li class="me-2">
                <a href="#"
                   :class="{
                     'active': shipDesignCreationStore.createModalTab === 'help',
                     'text-blue-500 border-blue-500': shipDesignCreationStore.createModalTab === 'help',
                     'text-slate-500 border-transparent': shipDesignCreationStore.createModalTab !== 'help',
                     'inline-block p-4 border-b-2 rounded-t-lg  hover:border-slate-300 hover:text-slate-300': true
                 }"
                   @click="shipDesignCreationStore.createModalTab = 'help'">
                  Help
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div v-if="shipDesignCreationStore.createModalTab === 'add'" class="pr-0 pt-2 w-full">
            <ModalCreateShipDesignAdd/>
          </div>
          <div v-if="shipDesignCreationStore.createModalTab === 'edit'" class="pr-0 pt-2 w-full">
            <ModalCreateShipDesignEdit/>
          </div>
          <div v-if="shipDesignCreationStore.createModalTab === 'help'" class="pr-0 pt-2 w-full">
            <ModalCreateShipDesignHelpTab/>
          </div>
        </div>
      </div>

    </div>
  </MediumPlusModel>
</template>

<style scoped lang="scss">

</style>