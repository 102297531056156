<script setup lang="ts">
import { onMounted, onUnmounted, nextTick, ref } from 'vue';
import { SVG, Svg } from '@svgdotjs/svg.js';
import { Bezier } from "bezier-js";
import TechnologyItem from '@/components/Sections/Research/TechnologyItem.vue';
import { useAssetStore } from '@/stores/assetStore.ts';
import { useGameStore } from '@/stores/gameStore.ts'

/*
 * Stores / models
 */
const assetStore = useAssetStore();
const gameStore = useGameStore();
const svgContainer = ref<Svg>();

/*
 * Variables
 */
const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const scrollLeft = ref(0);
const scrollTop = ref(0);

let containerElement: HTMLElement | null = null;

/*
 * Functions
 */
function onMouseDown(event: MouseEvent) {
  isDragging.value = true;
  startX.value = event.pageX - containerElement!.offsetLeft;
  startY.value = event.pageY - containerElement!.offsetTop;
  scrollLeft.value = containerElement!.scrollLeft;
  scrollTop.value = containerElement!.scrollTop;
  containerElement!.classList.add('grabbing'); // Add grabbing class
}

function onMouseMove(event: MouseEvent) {
  if (!isDragging.value) return;
  event.preventDefault();

  const x = event.pageX - containerElement!.offsetLeft;
  const y = event.pageY - containerElement!.offsetTop;
  const walkX = (x - startX.value); // Multiply by 1 for faster scrolling
  const walkY = (y - startY.value);

  containerElement!.scrollLeft = scrollLeft.value - walkX;
  containerElement!.scrollTop = scrollTop.value - walkY;
}

function onMouseUp() {
  isDragging.value = false;
  containerElement!.classList.remove('grabbing'); // Remove grabbing class
}

function drawLines() {
  if (!svgContainer.value) return;

  svgContainer.value.clear(); // Clear previous lines

  const containerElement = svgContainer.value.node.parentElement!;
  const parentBox = containerElement.getBoundingClientRect();



  let minX = Infinity, minY = Infinity, maxX = 0, maxY = 0;

  assetStore.techs.forEach((tech) => {
    const endElement = document.getElementById(`tech-${tech.code}`);

    if (!endElement) {
      console.warn(`End element not found for tech: ${tech.code}`);
      return;
    }

    tech.requirements.forEach((requirement: { code: string, type: string }) => {
      const startElement = document.getElementById(`tech-${requirement.code}`);

      if (!startElement) {
        console.warn(`Start element not found for requirement: ${requirement.code}`);
        return;
      }

      const startBox = startElement.getBoundingClientRect();
      const endBox = endElement.getBoundingClientRect();

      minX = Math.min(minX, startBox.left, endBox.left);
      minY = Math.min(minY, startBox.top, endBox.top);
      maxX = Math.max(maxX, startBox.right, endBox.right);
      maxY = Math.max(maxY, startBox.bottom, endBox.bottom);
    });
  });

  const offsetX = 0;   // Move viewBox 50px left
  const offsetY = 50;  // Move viewBox 100px up (so content appears lower)
  const padding = 50;   // Add extra space all around

  const width = maxX - minX;
  const height = maxY - minY;

// Expand the total width/height by twice the padding (on each side: left/right, top/bottom).
  const expandedWidth = width + padding * 2;
  const expandedHeight = height + padding * 2;

  svgContainer.value
    // Make the SVG <width> and <height> match the expanded sizes
    .size(expandedWidth, expandedHeight)
    // Shift the viewBox by offsetX / offsetY, plus incorporate padding
    .viewbox(
      (minX - padding) - offsetX,
      (minY - padding) - offsetY,
      expandedWidth,
      expandedHeight
    );

  // Create an arrow marker
  const marker = svgContainer.value.marker(5, 5, (add) => {
    add.path("M0,0 L0,6 L9,3 z").fill("#334155");
  }).ref(6, 3).viewbox(0, 0, 10, 10).orient('auto-start-reverse');

  assetStore.techs.forEach((tech) => {
  const endElement = document.getElementById(`tech-${tech.code}`);

  if (!endElement) {
    console.warn(`End element not found for tech: ${tech.code}`);
    return;
  }

  tech.requirements.forEach((requirement: { code: string, type: string }) => {
    const startElement = document.getElementById(`tech-${requirement.code}`);

    if (!startElement) {
      console.warn(`Start element not found for requirement: ${requirement.code}`);
      return;
    }

    const startBox = startElement.getBoundingClientRect();
    const endBox = endElement.getBoundingClientRect();

    // Check where the line should come out
    // If the endx is higher than startx, then use the right of the box
    let startBoxX = 0;
    let startBoxY = 0;
    let controlPoint1X = 0;
    let controlPoint1Y = 0;
    const offset = 5; // Offset to prevent arrow overlap

    if(startBox.left < endBox.left) {
      startBoxX = (startBox.left - parentBox.left) + startBox.width;
      startBoxY = (startBox.top - parentBox.top) + startBox.height / 2;
      controlPoint1X = startBoxX + 50;
      controlPoint1Y = startBoxY;
    } else if(startBox.left === endBox.left && startBox.top > endBox.top) {
      // Arrow UP
      startBoxX = (startBox.left - parentBox.left) + startBox.width /2;
      startBoxY = (startBox.top - parentBox.top);
      controlPoint1X = startBoxX;
      controlPoint1Y = startBoxY - 50;
    } else if(startBox.left === endBox.left && startBox.top < endBox.top) {
      // Arrow DOWN
      startBoxX = (startBox.left - parentBox.left) + startBox.width/2;
      startBoxY = (startBox.top - parentBox.top) + startBox.height;
      controlPoint1X = startBoxX;
      controlPoint1Y = startBoxY + 50;

    }

    let endBoxX = 0;
    let endBoxY = 0;
    let controlPoint2X = 0;
    let controlPoint2Y = 0;

    if (startBox.left < endBox.left) {
      endBoxX = (endBox.left - parentBox.left) - offset;
      endBoxY = (endBox.top - parentBox.top) + endBox.height / 2;
      controlPoint2X = endBoxX - 150;
      controlPoint2Y = endBoxY;
    } else if(startBox.left === endBox.left && startBox.top > endBox.top) {
      // Arrow UP
      endBoxX = (endBox.left - parentBox.left) + endBox.width / 2;
      endBoxY = (endBox.top - parentBox.top) + offset + (endBox.height);
      controlPoint2X = endBoxX;
      controlPoint2Y = endBoxY + 50;
    } else if(startBox.left === endBox.left && startBox.top < endBox.top) {
      // Arrow Down
      endBoxX = (endBox.left - parentBox.left) + endBox.width / 2;
      endBoxY = (endBox.top - parentBox.top) - offset;
      controlPoint2X = endBoxX;
      controlPoint2Y = endBoxY - 50;
    }

    const curve = new Bezier([
      { x: startBoxX, y: startBoxY },
      { x: controlPoint1X, y: controlPoint1Y }, // Control point 1
      { x: controlPoint2X, y: controlPoint2Y }, // Control point 2
      { x: endBoxX, y: endBoxY }
    ]);

    const svgPath = curve.toSVG();
    const line = svgContainer.value!.path(svgPath).stroke({ color: '#334155', width: 4 }).fill('none');

    // Attach the marker to the end of the line
    line.marker('end', marker);

  });

});
}

function focusOnCurrentResearch() {
  let currentTech = assetStore.findTechById(gameStore.player?.currentTechId ?? 0)
  if(!currentTech) {
    currentTech = assetStore.findTechById(1)
  }

  if(!currentTech) {
    console.warn('Current tech not found');
    return;
  }
  const targetElement = document.querySelector(`#tech-` + currentTech.code);
  if (targetElement) {
    const parentElement = containerElement;
    if(!parentElement) {
      console.warn('Parent element not found');
      return;
    }

    // Calculate the center position
    const parentCenterX = parentElement.clientWidth / 2;
    const parentCenterY = parentElement.clientHeight / 2;
    const targetCenterX = targetElement.offsetLeft + (targetElement.clientWidth / 2);
    const targetCenterY = targetElement.offsetTop + (targetElement.clientHeight / 2);

    const scrollLeft = targetCenterX - parentCenterX;
    const scrollTop = targetCenterY - parentCenterY;

    // Scroll to the calculated position
    parentElement.scrollTo({
      top: scrollTop,
      left: scrollLeft,
    });
  } else {
    console.warn('Target element not found: #tech-particle_physics');
  }
}


/*
 * Lifecycle hooks
 */
onMounted(async () => {
  await nextTick(); // Ensure the DOM is fully rendered

  // Assign the container element
  containerElement = document.querySelector('.tech-parent');
  if (containerElement) {
    containerElement.addEventListener('mousedown', onMouseDown);
    containerElement.addEventListener('mousemove', onMouseMove);
    containerElement.addEventListener('mouseup', onMouseUp);
    containerElement.addEventListener('mouseleave', onMouseUp);

    svgContainer.value = SVG().addTo(containerElement).size('100%', '100%');
  }

  drawLines();

  focusOnCurrentResearch()

});

onUnmounted(() => {
  if (containerElement) {
    containerElement.removeEventListener('mousedown', onMouseDown);
    containerElement.removeEventListener('mousemove', onMouseMove);
    containerElement.removeEventListener('mouseup', onMouseUp);
    containerElement.removeEventListener('mouseleave', onMouseUp);
  }

  if (svgContainer.value) {
    svgContainer.value.clear();
    svgContainer.value.remove();
  }
});
</script>

<template>
  <div class="relative w-full" @contextmenu.prevent>
    <div class="tech-parent content-height-limit cursor-grab select-none" >
      <template v-for="tech in assetStore.techs.values()" :key="tech.code">
        <TechnologyItem :id="'tech-' + tech.code" :tech="tech"></TechnologyItem>
      </template>
    </div>
  </div>
</template>

<style scoped>

.tech-parent {
  position: relative;
  width: 110%;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.tech-parent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.grabbing {
  cursor: grabbing !important;
}

.hide-scrollbar{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>
