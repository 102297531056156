<script setup lang="ts">

import { computed, useSlots } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  help: {
    type: String,
    required: false
  },
  topPadding: {
    type: String,
    required: false,
    default: '1'
  },
  bottomPadding: {
    type: String,
    required: false,
    default: '-'
  },
  noPadding: {
    type: Boolean,
    required: false,
    default: false
  }
})

// Check if the default slot has content
const slots = useSlots();
const slotHasContent = computed(() => {
  const slotContent = slots.default?.();
  return slotContent && slotContent.length > 0;
});

</script>

<template>
  <div :class="'mt-' + topPadding + ' mb-' + bottomPadding + ' text-subtext'">
    <div v-if="title" class="pl-2 py-1 text-slate-300 bg-slate-700 rounded-t overflow-x-clip text-nowrap" v-html="title"></div>
    <div v-if="help" class="text-subtext bg-slate-800 p-2 flex-grow rounded-b mb-1"> {{ help }}</div>
    <div v-if="slotHasContent" :class="{
      'bg-slate-800 flex-grow rounded-b': true,
      'rounded:': !title || help,
      'p-2': !noPadding
    }">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>