<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'

/**
 * Props
 */
const props = defineProps({
  productionObjectId: {
    type: Number,
    required: true
  },
  PQAId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const PQAStore = usePQAStore()

/**
 * Functions
 */
const addProductionObject = () => {
  PQAStore.addToQueue(productionObjectData.value.id)
}

/**
 * Computed
 */
const productionObjectData = computed(() => {
  return assetStore.findProductionObjectById(props.productionObjectId)!
})

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

</script>

<template>
  <StandardCard v-if="!PQAStore.isInQueue(productionObjectId)">
    <div class="flex flex-col">
      <!--- Image --->
      <div class="flex justify-between">
        <div class="flex-shrink-0">
          <img
            :src="CDN.building(128,buildingData.buildingCode)" class="rounded h-20" alt=""
          />
        </div>
        <div class="flex flex-grow flex-col">
          <div class="flex-grow text-subtext ps-2">
            {{ buildingData.buildingName }}
          </div>
          <!--- Icons --->
          <div class="flex justify-between">
            <div class="flex items-center pt-1 ps-2">
              <BuildingEffects v-if="buildingData" :building="buildingData"/>
            </div>
            <div class="flex items-end">
              <ActionButton @click="addProductionObject">
                <GenericIconWithNumber :amount="productionObjectData.productionCost" type="production" text-color="text-slate-300"/>
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>