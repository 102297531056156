<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import { computed, defineProps, ref, watch } from 'vue'

const gameStore = useGameStore()

const props = defineProps({
  type: {
    type: Number,
    required: true
  }
})

const typeName = computed<string>(() => {
  switch (props.type) {
    case 1:
      return 'lava';
    case 2:
      return 'desert';
    case 3:
      return 'temperate';
    case 4:
      return 'oceanic';
    case 5:
      return 'gas';
    case 6:
      return 'ice';
    case 7:
      return 'ferion';
    default:
      return 'Unknown';
  }
});

const videoElement = ref<HTMLVideoElement | null>(null);

watch(() => props.type, () => {
  if (videoElement.value) {
    videoElement.value.load(); // Reload the video when the type changes
  }
});

</script>

<template>
  <video ref="videoElement" autoplay muted loop playsinline>
    <source :src="gameStore.cdn + 'ferion/videos/planets/' + typeName + '/planet_header.webm'" type="video/webm">
  </video>
</template>

<style scoped lang="scss">

</style>
