<script setup lang="ts">

import ShipDesignComponentSelected
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/ShipDesignComponentSelected.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

/**
 * Stores / models
 */
const shipDesignCreateStore = useShipDesignCreationStore()

</script>

<template>
  <div class="h-[calc(100vh-17.5rem)] overflow-y-auto md:h-[calc(100vh-14.5rem)]">
    <div class="flex-grow grid grid-cols-2 gap-2">
      <template v-for="selectedComponent in shipDesignCreateStore.components" :key="selectedComponent.id">
        <ShipDesignComponentSelected :componentCode="selectedComponent.component.code" :index="selectedComponent.id"/>
      </template>
      <StandardCard v-if="shipDesignCreateStore.components.length===0" class="col-span-2" top-padding="0">
        Once you have added components to your ship design, they will appear here.
      </StandardCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style


