<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import PlanetProductionProgressSimpleObject
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionProgressSimpleObject.vue'
import router from '@/router'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import PlanetMultiSelectMenu from '@/components/Interface/Menu/PlanetMultiSelectMenu.vue'
import PlanetCompatibility from '@/components/Objects/PlanetCompatibility.vue'
import TagButton from '@/components/Interface/Buttons/TagButton.vue'
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'
import Backend from '@/models/backend.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'
import type PlayerPlanet from '@/types/PlayerPlanet.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

/**
 * Stores / models
 */
const planetStore = usePlanetStore();
const tableStore = useTableStore();
const gameStore = useGameStore();
const planetGroupStore = usePlanetGroupStore();

/**
 * Functions
 */
// Load filtered ships list
const filters = (planets:Map<number,PlayerPlanet>) => {
  return Array.from(planets.values())
    .filter(planet => planet.playerId === gameStore.player?.id)
    .filter((planet) => {
      // If no type is selected, return all
      if (!tableStore.planets.filters.selectedCompatible || tableStore.planets.filters.selectedCompatible === '') {
        return true;
      }
      // Otherwise, filter based on the selected category
      return planet.compatibility === tableStore.planets.filters.selectedCompatible;
    })
    .filter((planet) => {
      // If no type is selected, return all
      if (! tableStore.planets.filters.noProduction || tableStore.planets.filters.noProduction === 0) {
        return true;
      }
      // Otherwise, filter based on setting
      return Number(planet.productionObjectId) === 0;
    })
    .filter((planet) => {
      // All
      if (tableStore.planets.filters.selectedGroup === 0) {
        return true;
      }
      // Not in group
      if (tableStore.planets.filters.selectedGroup === -1) {
        return planet.planetGroupId === 0;
      }

      // Otherwise, filter based on the selected group
      return planet.planetGroupId === tableStore.planets.filters.selectedGroup;
    })
    .filter((planet) => {
      // If no search query, return all
      if (!tableStore.planets.filters.nameSearch || tableStore.planets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return planet.planetName.toLowerCase().includes(tableStore.planets.filters.nameSearch.toLowerCase());
    });
};

const loadItem = (id:number) => {
  router.push('/planets/' + id);
}

const openGroupModal = () => {
  gameStore.modalType = 'createPlanetGroup';
};

const filterGroup = (groupId: number) => {
  tableStore.planets.filters.selectedGroup = groupId;
};

const deleteGroup = (groupId: number) => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this group?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.deletePlanetGroup(groupId)
    },
  };

};

</script>

<template>
  <div class="mb-1 text-slate-300">
    <TagButton :selected="false" @click="openGroupModal">+</TagButton>
    <TagButton :selected="tableStore.planets.filters.selectedGroup===0" @click="filterGroup(0)">All</TagButton>
    <TagButton :selected="tableStore.planets.filters.selectedGroup===-1" @click="filterGroup(-1)">Not in group</TagButton>
    <template v-for="item in planetGroupStore.planetGroups.values()" :key="item.id">
      <TagButton :selected="tableStore.planets.filters.selectedGroup===item.id" @click="filterGroup(item.id)">
        <div class="flex w-full">
          <div>{{ item.name }}</div>
          <div
            v-if="tableStore.planets.filters.selectedGroup===item.id"
            class="text-red-600 bg-slate-700 hover:text-slate-300 hover:bg-red-500 ml-1 px-1 rounded"
            @click.stop="deleteGroup(item.id)"
          >x</div>
        </div>
      </TagButton>
    </template>
  </div>
  <FerionDataTable
    :data="planetStore.planets"
    tableStoreName="planets"
    :selector="true"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-8rem)]'"
    :click="loadItem"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center px-1 rounded">
        <PlanetMultiSelectMenu>
          <ActionButton class="my-1">Action</ActionButton>
        </PlanetMultiSelectMenu>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1 rounded"></ActionBarFiller>
      <ActionBarBlock class="flex items-center text-nowrap rounded mr-1">
        <div class="bg-slate-800 text-slate-300 flex items-center px-2 pt-0 mt-0">No production</div>
        <div class="pr-2">
          <label class="inline-flex items-center mt-1.5 cursor-pointer">
            <input type="checkbox" v-model="tableStore.planets.filters.noProduction" class="sr-only peer">
            <div class="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-slate-300 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-slate-300 after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600"></div>
          </label>
        </div>
      </ActionBarBlock>
      <ActionBarSearch v-model="tableStore.planets.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1 rounded"/>
      <ActionBarSelect name="Compat." width="6" v-model="tableStore.planets.filters.selectedCompatible" class="">
        <option value="" selected>All</option>
        <option value="A">Paradise</option>
        <option value="B">Lush</option>
        <option value="C">Habitable</option>
        <option value="D">Incompatible</option>
        <option value="E">Toxic</option>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="12" column="planetName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthIcon width="4" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <div class="flex justify-center">
          <PlanetIcon :size="2" :type="5"></PlanetIcon>
        </div>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="capturedAtTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="redflag"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="population" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="person"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="populationGrowthPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="food"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="productionPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="production"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="sciencePerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="science"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="creditsPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="credit"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthText class="rounded-tr" width="15" column="productionObjectId" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="left">
        Production
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="12">
        <div class="flex-col">
          <div>
            <span :class="{
              'text-red-500': item.abandon == 1
            }">{{ item.planetName }}</span> <PlanetCompatibility :compatibility="item.compatibility" :short="true"/>
          </div>
          <div v-if="item.planetGroupId>0" class="text-subtext text-xs">{{ planetGroupStore.getNameFromId(item.planetGroupId) }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="4">
        <PlanetIcon :size="item.size" :type="item.type"></PlanetIcon>
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ item.capturedAtTurn }}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.population }}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <ColoredNumber :amount="item.populationGrowthPerTurn" :allowZero="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <ColoredNumber :amount="item.productionPerTurn" :allowZero="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <ColoredNumber :amount="item.sciencePerTurn" :allowZero="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <ColoredNumber :amount="item.creditsPerTurn" :allowZero="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="15" align="left">
        <PlanetProductionProgressSimpleObject :planet-id="item.id"></PlanetProductionProgressSimpleObject>
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>