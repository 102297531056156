<script setup lang="ts">
import router from '@/router'
import PlanetList from '@/components/Sections/Planets/PlanetList.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { usePlanetStore } from '@/stores/planetStore.ts'

/**
 * Stores / models
 */
const tableStore = useTableStore();
const route = useRoute();
const planetStore = usePlanetStore();

/**
 * Lifecycle
 */
onMounted(() => {
  if(Number(route.params.planetId) > 0) {
    // Load planet details from URL
    tableStore.planets.singleSelectedId = Number(route.params.planetId);
  } else if(tableStore.planets.singleSelectedId > 0) {
    // Load from last selected planet
    //only when we are on portrait mode mobile
    if (window.innerWidth > 500) {
      router.push('/planets/' + tableStore.planets.singleSelectedId);
    }
  } else {
    // Load first planet from list
    if (planetStore.planets.size > 0 && window.innerWidth > 500) {
      router.push('/planets/' + planetStore.planets.values().next().value.id);
    }
  }
});

</script>

<template>
  <div class="bg-slate-900">
    <div class="flex flex-col md:flex-row pt-1">
      <div :class="{
          'w-full md:w-1/2 px-1' : true,
          'hidden sm:block' : Number(route.params.planetId) > 0
        }">
        <div class="content-height-limit px-1 pt-1">
          <PlanetList/>
        </div>
      </div>
      <div class="w-full md:w-1/2 pr-2 mt-1 pl-2 md:pl-0 content-height-limit">
        <div class="bg-slate-900 rounded text-slate-300">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
