<script setup lang="ts">

import PlayerName from '@/components/Objects/PlayerName.vue'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import {useGameStore} from '@/stores/gameStore.ts'
import {computed} from "vue";
import {useAssetStore} from "@/stores/assetStore.ts";
import router  from '@/router'
import PlanetAnimation from '@/components/Elements/Animations/Planet/PlanetAnimation.vue'
import PlanetSizeAnimation from '@/components/Elements/Animations/Planet/PlanetSizeAnimation.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()


const props = defineProps<{
  coordinate: MapCoordinate
}>();

const planetTypeData = computed(() => {
  return assetStore.findPlanetTypeById(props.coordinate.typeVariant)
})

const openPlanet = () => {
  if(props.coordinate.typePlayerId === gameStore.player?.id) {
    router.push('/planets/' + props.coordinate.typeId)
  }
}

</script>

<template>
  <div :class=" {
    'background border-slate-800 border' : true,
    'cursor-pointer' : coordinate.typePlayerId === gameStore.player?.id
  }" @click="openPlanet">
    <div class="flex justify-between">
      <div class="text-center text-subtext p-1">
        Size:
        <span :class="{
          'text-green-500' : coordinate.typeSize === gameStore.player?.homeWorldPlanetSize,
          'text-red-500' : coordinate.typeSize === gameStore.player?.homeWorldOppositePlanetSize,
        }">{{ coordinate.typeSize }}</span>
      </div>
      <div class="text-center text-subtext p-1">
        <span :class="{
          'text-green-500' : coordinate.typeVariant === gameStore.player?.homeWorldPlanetType,
          'text-red-500' : coordinate.typeVariant === gameStore.player?.homeWorldOppositePlanetType,
        }">{{ planetTypeData?.name }}</span>
      </div>
    </div>
    <div class="flex justify-center items-center h-36">
      <PlanetSizeAnimation :size="coordinate.typeSize" :type="coordinate.typeVariant" :time="12"></PlanetSizeAnimation>
    </div>
    <div class="text-center p-1">
      <PlayerName :playerId="Number(props.coordinate.typePlayerId)"></PlayerName>
      <span class="ps-2 cursor-help" v-if="!coordinate.currentlyVisible" title="Last known owner, you have not visited this planet in a while.">H</span>
    </div>
<!--    <div class="absolute left-0 bottom-0 pb-2 pl-2">-->
<!--      <img v-if="coordinate.typePlayerId===0" :src="'' + gameStore.cdn + '/images/ship/actions/settle.png'" alt="Send settler" title="Send settler" class="h-4 w-4 mr-1 cursor-pointer" />-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="scss">
.background {
  background-image: url("https://cdn.galexion.com/images/map/bg75d.jpg");
}

.planet-image-3 {
  height: 10rem;
  width: 10rem;
}
.planet-image-2 {
  height: 5rem;
  width: 5rem;
  margin: 2.5rem;
}
.planet-image-1 {
  height: 2rem;
  width: 2rem;
  margin: 4rem;
}
</style>