<script setup lang="ts">
import {useGameStore} from "@/stores/gameStore.ts";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { CDN } from '@/models/cdn.ts'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()
const planetStore = usePlanetStore()

const outputOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.outputOreId)
})

const leftOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.inputOreLeftId)
})

const rightOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.inputOreRightId)
})

const factoryCount = computed(() => {
  return planetStore.countBuildingsOfType(gameStore.modalData.buildingType)
})

</script>

<template>
  <MediumModel :title="outputOre.oreName + ' Refinery'">
    <div class="flex flex-col sm:flex-row text-center sm:text-left">
      <ImageRoundedUrl
        :url="CDN.building(512,gameStore.modalData.buildingType)"
        :size="16"
        class="m-2 self-center sm:self-start"
      />
      <div class="text-subtext p-2">
        <div class="pb-2">Converts {{outputOre.dependency1ConversionRate}} {{ leftOre.oreName }} and {{outputOre.dependency2ConversionRate}} {{ rightOre.oreName }} into 1 {{ outputOre.oreName }}</div>
        <div class="border-t border-t-slate-700 pt-2">
          <div>Factories <span class="text-white">{{ factoryCount }}</span></div>
          <div>Processed last week <span class="text-white">{{ gameStore.modalData.capacity }}</span></div>
        </div>
      </div>
    </div>
  </MediumModel>
</template>


<style scoped lang="scss">

</style>