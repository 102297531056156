<script setup lang="ts">

import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import OreFactory from '@/components/Sections/Resources/Elements/OreFactory.vue'

const oreConversionStore = useOreConversionStore()

const debug = false
</script>

<template>

  <!-- Level 1 -->
  <OreFactory
    :oreLeft="1"
    :oreRight="2"
    :oreProcessed="13"
    :conversionLeft="oreConversionStore.data.convertBezantiumToKarmasas"
    :conversionRight="oreConversionStore.data.convertRadiusantToKarmasas"
    :processed="oreConversionStore.data.produceKarmasas"
    :top="18"
    :left="6"
    :height="4"
    :width="8"
    :buildingType="'karmasas_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="3"
    :oreRight="4"
    :oreProcessed="14"
    :conversionLeft="oreConversionStore.data.convertNucloidToNadzerbat"
    :conversionRight="oreConversionStore.data.convertVerrotanToNadzerbat"
    :processed="oreConversionStore.data.produceNadzerbat"
    :top="18"
    :left="26"
    :height="4"
    :width="8"
    :buildingType="'nadzerbat_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="5"
    :oreRight="6"
    :oreProcessed="15"
    :conversionLeft="oreConversionStore.data.convertBoturkoecToGalagores"
    :conversionRight="oreConversionStore.data.convertNagazalToGalagores"
    :processed="oreConversionStore.data.produceGalagores"
    :top="18"
    :left="46"
    :height="4"
    :width="8"
    :buildingType="'galagores_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="7"
    :oreRight="8"
    :oreProcessed="16"
    :conversionLeft="oreConversionStore.data.convertFormetsToMiout"
    :conversionRight="oreConversionStore.data.convertGrindazineToMiout"
    :processed="oreConversionStore.data.produceMiout"
    :top="18"
    :left="66"
    :height="4"
    :width="8"
    :buildingType="'miout_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="9"
    :oreRight="10"
    :oreProcessed="17"
    :conversionLeft="oreConversionStore.data.convertPerungaToLdwarts"
    :conversionRight="oreConversionStore.data.convertVeroniumToLdwarts"
    :processed="oreConversionStore.data.produceLdwarts"
    :top="18"
    :left="86"
    :height="4"
    :width="8"
    :buildingType="'ldwarts_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="11"
    :oreRight="12"
    :oreProcessed="18"
    :conversionLeft="oreConversionStore.data.convertBlazanterToMankazor"
    :conversionRight="oreConversionStore.data.convertTerrilliodToMankazor"
    :processed="oreConversionStore.data.produceMankazor"
    :top="18"
    :left="106"
    :height="4"
    :width="8"
    :buildingType="'mankazor_refinery'"
    :debug="debug"
  />

  <!-- Level 2 -->

  <OreFactory
    :oreLeft="13"
    :oreRight="14"
    :oreProcessed="19"
    :conversionLeft="oreConversionStore.data.convertKarmasasToFerrosnr6"
    :conversionRight="oreConversionStore.data.convertNadzerbatToFerrosnr6"
    :processed="oreConversionStore.data.produceFerrosnr6"
    :top="54"
    :left="16"
    :height="4"
    :width="8"
    conveyorType="level2"
    :buildingType="'ferrosnr6_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="15"
    :oreRight="16"
    :oreProcessed="20"
    :conversionLeft="oreConversionStore.data.convertGalagoresToSjampoo"
    :conversionRight="oreConversionStore.data.convertMioutToSjampoo"
    :processed="oreConversionStore.data.produceSjampoo"
    :top="54"
    :left="56"
    :height="4"
    :width="8"
    conveyorType="level2"
    :buildingType="'sjampoo_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="17"
    :oreRight="18"
    :oreProcessed="21"
    :conversionLeft="oreConversionStore.data.convertLdwartsToTexorrium"
    :conversionRight="oreConversionStore.data.convertMankazorToTexorrium"
    :processed="oreConversionStore.data.produceTexorrium"
    :top="54"
    :left="96"
    :height="4"
    :width="8"
    conveyorType="level2"
    :buildingType="'texorrium_refinery'"
    :debug="debug"
  />

  <!-- Level 3 -->

  <OreFactory
    :oreLeft="19"
    :oreRight="20"
    :oreProcessed="22"
    :conversionLeft="oreConversionStore.data.convertFerrosnr6ToKroketite"
    :conversionRight="oreConversionStore.data.convertSjampooToKroketite"
    :processed="oreConversionStore.data.produceKroketite"
    :top="90"
    :left="36"
    :height="4"
    :width="8"
    conveyorType="level3"
    :buildingType="'kroketite_refinery'"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="20"
    :oreRight="21"
    :oreProcessed="23"
    :conversionLeft="oreConversionStore.data.convertSjampooToRabogen"
    :conversionRight="oreConversionStore.data.convertTexorriumToRabogen"
    :processed="oreConversionStore.data.produceRabogen"
    :top="90"
    :left="76"
    :height="4"
    :width="8"
    conveyorType="level3"
    :buildingType="'rabogen_refinery'"
    :debug="debug"
  />

  <!-- Level 4 -->

  <OreFactory
    :oreLeft="22"
    :oreRight="23"
    :oreProcessed="24"
    :conversionLeft="oreConversionStore.data.convertKroketiteToDropium"
    :conversionRight="oreConversionStore.data.convertRabogenToDropium"
    :processed="oreConversionStore.data.produceDropium"
    :top="126"
    :left="56"
    :height="4"
    :width="8"
    conveyorType="level3"
    :buildingType="'dropium_refinery'"
    :debug="debug"
  />


</template>

<style scoped lang="scss">

</style>