<script setup lang="ts">

import { usePQAStore } from '@/stores/PQAStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { ref, onMounted } from 'vue'

const pqaStore = usePQAStore()
const gameStore = useGameStore()

const playerSettlePQA = ref(0)

const setPQA = () => {
  const backend = new Backend()
  backend.setPlayerSettlePQA(playerSettlePQA.value).then((result) => {
    gameStore.modalType=''
  })
}

onMounted(() => {
  playerSettlePQA.value = gameStore.player?.settlePQA ?? 0
})


</script>

<template>
  <div class="text-slate-300">
    <div class="pb-2 ">Select the production queue you wish to assign to newly settled planets</div>
    <div>
      <select v-model="playerSettlePQA" @change="setPQA" class="rounded">
        <template v-for="pqa in pqaStore.queues.values()" :key="pqa.id">
          <option :value="pqa.id">{{ pqa.name}}</option>
        </template>
      </select>
    </div>
  </div>
</template>

<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-300 rounded focus:outline-none
}
</style>