<script setup lang="ts">

import { computed, defineProps, type PropType, ref } from 'vue'
import type OreConversion from '@/types/OreConversion.ts'
import { formatShort } from '@/models/formatNumber.ts'

const props = defineProps({
  conversion: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  objectImage: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  midTime: {
    type: Number,
    required: false,
    default: 0.5
  },
  minSpeed: {
    type: Number,
    required: false,
    default: 1
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const hover = ref(false);

const conveyorColor = computed(() => {
  if (props.conversion.capacity == 0) {
    return '#1E293C';
  }
  return 'black'; // Green if both conversions are positive
});

const oreSpeed = computed(() => {
  if (props.conversion.capacity <= 0) {
    return 0; // No speed if ore processed is zero or negative
  }

  // Calculate logarithmic speed inversely
  const logValue = Math.log10(1000 / props.conversion.capacity);

  // Define a base speed that increases with ore processed
  return Math.max(props.minSpeed, logValue); // Ensure a minimum speed of 0.3
});


</script>

<template>
  <svg :class="{
          'absolute':true,
          'border':debug
        }"
       :style="'top:' + top + 'rem;left:' + left + 'rem;width:' + width + 'rem;height:' + height + 'rem;'"
       xmlns="http://www.w3.org/2000/svg"
  >

    <!-- Background rail -->
    <rect x="0"
          y="0"
          :width="width + 'rem'"
          height="1rem"
          :fill="conveyorColor"
          @mouseenter="hover = true"
          @mouseout="hover = false"/>
    <rect x="0"
          y="0"
          width="1rem"
          :height="height + 'rem'"
          :fill="conveyorColor"
          @mouseenter="hover = true"
          @mouseout="hover = false"/>

    <image
      v-if="conversion.capacity > 0"
      :href="props.objectImage"
      x="0"
      y="0"
      width="1rem"
      height="1rem"
      @mouseenter="hover = true"
      @mouseout="hover = false"
      class="cursor-pointer"
    >
      <!-- Animate the downward movement -->
      <animate
        attributeName="y"
        :values="'0;0;' + (height-1) + 'rem'"
        :keyTimes="'0;' + midTime + ';1'"
        :dur="oreSpeed + 's'"
        repeatCount="indefinite" />
      <!-- Animate the leftward movement -->
      <animate
        attributeName="x"
        :values=" width + 'rem;0;0'"
        :keyTimes="'0;' + midTime + ';1'"
        :dur="oreSpeed + 's'"
        repeatCount="indefinite" />
    </image>

  </svg>

  <div v-if="props.conversion.capacity>0 && hover" class="absolute text-green-500 border p-1 rounded border-slate-600 bg-slate-800 text-center" :style="'top:' + (top - 2.25) + 'rem;left:' + (left -1) + 'rem; width:3rem;z-index:18;'">{{ formatShort(props.conversion.capacity) }}</div>
</template>

<style scoped lang="scss">

</style>