<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetPopulationSetting from "@/components/Sections/Planets/PlanetPopulationSetting.vue";
import PlanetTabs from "@/components/Sections/Planets/PlanetTabs.vue";
import Position from "@/components/Interface/Position.vue";
import PlanetHeaderVideo from '@/components/Elements/Videos/PlanetHeaderVideo.vue'
import PlanetPill from '@/components/Sections/Planets/PlanetTabs/Generic/PlanetPill.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

</script>

<template>
  <div v-if="props.planetId !==0 && planetData">
    <div class="planetHeader flex flex-col justify-between relative rounded-t">
      <!-- Video Background -->
        <PlanetHeaderVideo
          :type="Number(planetData.type)"
          class="absolute inset-0 w-full h-full object-cover"
        />
      <!-- Foreground Content -->
      <div class="absolute left-0 p-2 w-full">
        <div class="text-xl sm:text-2xl flex justify-between w-full items-center">
          <div class="flex items-center">
            <ModalTrigger type="editPlanetName" :data="{planetId: props.planetId}">
              {{ planetData.planetName }}
            </ModalTrigger>
            <Position :x="planetData.xPos" :y="planetData.yPos" class="ms-1"></Position>
          </div>
        </div>
        <p class="text-subtext">{{ planetData.starName }}</p>
      </div>

      <div class="absolute left-0 bottom-0 p-2 flex">
      </div>

      <div class="absolute right-0 bottom-0 pr-1 pb-1" >
        <PlanetPill type="population" :amount="planetData.population"/>
      </div>
    </div>

    <div class="bg-slate-800 mb-4 rounded-b">
      <PlanetPopulationSetting :planetId="planetData.id" class="pt-2 pb-2"></PlanetPopulationSetting>
    </div>
    <div>
      <PlanetTabs :planetId="planetData.id"></PlanetTabs>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-center text-subtext p-10">No planet selected</div>
  </div>

</template>

<style scoped>
.planetHeader{
  height: 12rem;
  overflow: hidden;
}


</style>
