<script setup lang="ts">
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { type Ref, ref, onMounted } from 'vue'
import type ProductionObject from '@/types/ProductionObject.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()

const backend = new Backend()

const replaceCurrentObject = ref(0)

/**
 * Set the production object
 */
const setProductionObject = (productionObjectId:number) => {
    backend.setPlanetsProductionObject(gameStore.modalData.planetIds, productionObjectId,replaceCurrentObject.value == 1);
    gameStore.setTinySuccessToastMessage('Building added to production queue');
}

const availableProductionObjects: Ref<ProductionObject[]> = ref([]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {
  // Get all available production objects for the player
  const productionObjectsMap = gameStore.playerProductionObjects;

  if (productionObjectsMap && productionObjectsMap.size > 0) {
     // Sort by cost
    availableProductionObjects.value = Array.from(productionObjectsMap.entries())
      .map(([id]) => {
        const productionObject = assetStore.findProductionObjectById(id);
        if (!productionObject) {
          throw new Error(`Production object with ID ${id} not found.`);
        }
        return productionObject;
      })
      .sort((a, b) => a.productionCost - b.productionCost);
  }
}

onMounted(() => {
  updateList();
})

</script>

<template>
  <SmallModel title="Build building">
    <div class="bg-slate-800">
      <div style="max-height: 25rem;" class="overflow-y-scroll  pl-1">
        <table class="w-full" >
          <tbody>
          <template v-for="productionObject in availableProductionObjects" :key="productionObject.id">
            <tr v-if="productionObject.type === 'building'">
              <td class="px-2 py-3 text-white text-left">
                {{ productionObject.productionObjectName }}
              </td>
              <td class="px-2 py-3 text-left text-subtext">
                <BuildingEffects :building="assetStore.findBuildingByCode(productionObject.objectId)"> </BuildingEffects>
              </td>
              <td class="px-2 py-3 text-right">
                <Icon type="production" class="pr-1"></Icon><IconCount :amount="productionObject.productionCost" :no-plus="true"/>
              </td>
              <td class="px-2 py-3 text-right">
                <StandardButton @click="setProductionObject(productionObject.id)">Build</StandardButton>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div class="content-header">Options</div>
      <div class="flex justify-center pb-2 pt-2 text-subtext">
        <div class="flex items-center pr-2">Replace current object in production</div>
        <div class="ps-2">
          <select v-model="replaceCurrentObject">
            <option value="0" selected>No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>