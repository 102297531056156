<script setup lang="ts">

</script>

<template>
<div class="bg-slate-800 text-slate-300 flex-grow">
  <slot></slot>
</div>
</template>

<style scoped lang="scss">

</style>