<script setup lang="ts">

import { defineProps, onUnmounted } from 'vue'
import {usePlanetStore} from "@/stores/planetStore";
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import PlanetCurrentProduction from '@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetCurrentProduction.vue'
import PlanetProductionQueue from '@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionQueue.vue'
import PlanetProductionAvailable
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionAvailable.vue'
import PlanetProductionMenu from '@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionMenu.vue'
import PlanetProductionAutomations
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionAutomations.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Functions
 */


/**
 * Lifecycle
 */
onUnmounted(() => {
  planetStore.planetProductionDisplayMode = ''
})


</script>

<template>
  <div class="flex pt-1" style="max-width: 40rem;">
    <div class="flex flex-shrink-0 me-2">
      <PlanetProductionMenu :planetId="props.planetId"/>
    </div>
    <div v-if="planetStore.planetProductionDisplayMode === 'production'" class="flex-grow">
      <StandardCard title='Production Selector' help="Select what your planet should build next."/>
      <PlanetProductionAvailable :planetId="props.planetId"/>
    </div>
    <div v-else-if="planetStore.planetProductionDisplayMode === ''" class="flex-grow">
      <PlanetCurrentProduction :planetId="props.planetId"/>
      <PlanetProductionQueue :planetId="props.planetId"/>
    </div>
    <div v-else-if="planetStore.planetProductionDisplayMode === 'pqa'" class="flex-grow">
      <PlanetProductionAutomations :planetId="props.planetId"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
