<script setup lang="ts">

import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed, defineProps } from 'vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore();
const gameStore = useGameStore();
const assetStore = useAssetStore();

/**
 * Variables
 */
const list = [
  'all',
  'food',
  'production',
  'science',
  'credit',
  'energy',
  'energy_storage',
  'ore_storage',
  'scanning_range',
  'defence_attack_ships',
  'defence_shields',
  'ore_refinery',
  'ore'
];

/**
 * Functions
 */
const setSelectedType = (type: string) => {
  planetStore.planetProductionMenuType = type;
};

const toggleProductionMode = () => {
  if(planetStore.planetProductionDisplayMode !== 'production') {
    planetStore.planetProductionDisplayMode = 'production';
  } else {
    planetStore.planetProductionDisplayMode = '';
  }
};

const togglePQAMode = () => {
  if(planetStore.planetProductionDisplayMode !== 'pqa') {
    planetStore.planetProductionDisplayMode = 'pqa';
  } else {
    planetStore.planetProductionDisplayMode = '';
  }
};

const clearQueue = () => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to clear the production queue?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.clearProductionFromPlanet([planetData.value.id]);
      gameStore.setTinySuccessToastMessage('Production queue cleared');
    },
  };
};

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!);

const filteredList = computed(() => {
  const productionObjectsMap = gameStore.playerProductionObjects;
  if (!productionObjectsMap || productionObjectsMap.size === 0) {
    return [];
  }

  // 1. Retrieve all production objects from the map
  let productionObjects = Array.from(productionObjectsMap.keys())
    .map((id) => {
      const productionObject = assetStore.findProductionObjectById(id);
      if (!productionObject) {
        throw new Error(`Production object with ID ${id} not found.`);
      }
      return productionObject;
    });

  // 2. Filter to include only buildings
  productionObjects = productionObjects.filter((obj) => obj.type === 'building');

  // 3. Filter out buildings that are already built, being built, or in the queue
  const planet = planetStore.findPlanetById(props.planetId);
  if (!planet) {
    throw new Error(`Planet with ID ${props.planetId} not found.`);
  }

  const { buildings = [], productionObjectId, productionQueue = [] } = planet;

  productionObjects = productionObjects.filter((obj) => {
    // Already constructed
    if (buildings.includes(obj.objectId)) return false;
    // Currently in production
    if (productionObjectId && obj.id === productionObjectId) return false;
    // In the production queue
    if (productionQueue.some((item) => item.productionObjectId === obj.id)) return false;
    return true;
  });

  // 4. Collect all effect types into a Set (avoids duplicates)
  const effectTypes = new Set<string>();

  // "all" is always included
  effectTypes.add('all');

  productionObjects.forEach((obj) => {
    const building = assetStore.findBuildingByCode(obj.objectId);
    if (!building) {
      throw new Error(`Building code ${obj.objectId} not found.`);
    }
    building.effects.forEach((effect: { type: string }) => {
      effectTypes.add(effect.type);
    });
  });

  // 5. Sort the found effect types according to the predefined 'list' order
  //    Only include items that exist in 'effectTypes'
  return list.filter((type) => effectTypes.has(type));
});


</script>

<template>
  <div class="basis-12 pt-1">
    <!--- Production Automation Cog --->
    <div
      @click="togglePQAMode"
      :class="{
            ' p-2 mb-1 px-3 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': planetStore.planetProductionDisplayMode === 'pqa',
            'bg-slate-800 hover:bg-slate-700': planetStore.planetProductionDisplayMode !== 'pqa'
          }">
      <GenericIcon type="cog"/>
    </div>
    <!--- Clear Production Queue --->
    <div
      @click="clearQueue"
      class="p-2 mb-1 px-3 flex items-center justify-between cursor-pointer rounded bg-slate-800 hover:bg-slate-700">
      <GenericIcon type="trash"/>
    </div>
    <!--- Production Mode Toggle --->
    <div
      @click="toggleProductionMode"
      :class="{
            ' p-2 mb-1 px-3 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': planetStore.planetProductionDisplayMode === 'production',
            'bg-slate-800 hover:bg-slate-700': planetStore.planetProductionDisplayMode !== 'production'
          }">
      <GenericIcon :type="'production'"/>
    </div>
    <!--- Production Type Icons --->
    <template v-for="item in filteredList">
      <div
        v-if="planetStore.planetProductionDisplayMode === 'production'"
        @click="setSelectedType(item)"
        :class="{
            ' p-2 mb-1 px-3 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': planetStore.planetProductionMenuType === item,
            'bg-slate-800 hover:bg-slate-700': planetStore.planetProductionMenuType !== item
          }">
        <GenericIcon v-if="item != 'all'" :type="item" :class="{'grayscale': planetStore.planetProductionMenuType !== item}"/>
        <div v-else class="text-slate-300">All</div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>