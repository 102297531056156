<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'

const props = defineProps({
  type: {
    type: String,
    required: true
  }
});

</script>

<template>
  <img :src="CDN.icon(props.type)" class="inline fixed-size" alt="">
</template>

<style scoped lang="scss">
.fixed-size {
  width: 20px;
  height: 20px;
}
</style>