<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useShipDesignStore } from '@/stores/shipDesignStore.ts'
import { computed, defineProps } from 'vue'
import Backend from '@/models/backend.ts'
import { useFleetStore } from '@/stores/fleetStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  },
  queueId: {
    type: Number,
    required: true
  },
  productionObjectId: {
    type: Number,
    required: true
  },
  productionShipDesignId: {
    type: Number,
    required: true
  },
  productionShipFleetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()
const fleetStore = useFleetStore()
const shipDesignStore = useShipDesignStore()
const backend = new Backend()

/**
 * Computed
 */
const productionObjectData = computed(() => assetStore.findProductionObjectById(props.productionObjectId))

const shipDesignData = computed(() => shipDesignStore.getShipDesignById(props.productionShipDesignId))

const name = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.name
  } else {
    return productionObjectData.value.productionObjectName
  }
});

const constructionCost = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.productionCost
  } else {
    return productionObjectData.value.productionCost
  }
});

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})


/**
 * Methods
 */
const deleteFromQueue = () => {
  backend.removeProductionObjectFromQueue(props.planetId, props.queueId);
  gameStore.setTinySuccessToastMessage('Production object removed from queue');
}

</script>

<template>
  <StandardCard :title="name" bottom-padding="0" top-padding="2">
    <div class="flex flex-col">
      <!--- Image --->
      <div class="flex justify-between">
        <div class="flex-shrink-0">
        <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
          <img
            :src="CDN.building(128,productionObjectData.objectId)" class="rounded h-20" alt=""
          />
        </ModalTrigger>
        <img
          v-else
          :src="CDN.ship(128)"
          class="rounded h-20"
          alt="">
        </div>
        <div class="flex flex-grow flex-col">
          <div class="flex-grow text-subtext ps-2">
            <span v-if="productionShipFleetId>0" class="pt-3">Build ship and assign to fleet <b class="text-slate-400">{{ fleetStore.getFleetNameById(productionShipFleetId) }}</b></span>
            <span v-else>{{ productionObjectData.description }}</span>
          </div>
          <!--- Icons --->
          <div class="flex justify-between">
            <div class="flex justify-start">
              <div class="flex items-center pt-1 ps-2">
                <BuildingEffects v-if="buildingData" :building="buildingData"/>
              </div>
            </div>
            <div class="flex items-end">
              <DeleteButton @click="deleteFromQueue" :for-icon="true">
                <GenericIcon type="trash" />
              </DeleteButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>
