<script setup lang="ts">

import { computed } from 'vue'
import PlanetAnimation from '@/components/Elements/Animations/Planet/PlanetAnimation.vue'

const props = defineProps({
  type: {
    type: Number,
    required: true,
    validator: (value: number) => {
      return [1, 2, 3, 4, 5, 6, 7].includes(value);
    }
  },
  size: {
    type: Number,
    required: true,
    validator: (value: number) => {
      return [1,2,3].includes(value);
    }
  },
  time: {
    type: Number,
    required: false,
    default: 1
  }
})

// Get the size of the planet animation based of the planet size
const planetAnimationSize = computed(() => {
  if(props.size === 1) {
    return 64
  }
  if(props.size === 2) {
    return 96
  }
  return 128
})

</script>

<template>
  <PlanetAnimation :size="planetAnimationSize" :type="type" :time="time"/>
</template>

<style scoped lang="scss">

</style>