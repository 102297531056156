<script setup lang="ts">

import { defineProps } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'

/**
 * Props
 */
const props = defineProps({
  fleetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const gameStore = useGameStore();


/**
 * Functions
 */
const disbandFleet = () => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to disband this fleet?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend()
      backend.disbandFleet(props.fleetId);
    },
  };

};

</script>

<template>
  <StandardCard top-padding="2" title="Disband fleet" help="Disbanding will detach all ships from this fleet and delete the Fleet entity. Ships from the fleet will cancel any existing orders and go Idle.">
    <DeleteButton @click="disbandFleet">Disband Fleet</DeleteButton>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>