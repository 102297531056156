<script setup lang="ts">

import {useAssetStore} from "@/stores/assetStore.ts";
import {useShipDesignCreationStore} from "@/stores/shipDesignCreationStore.ts";
import ShipDesignComponent from "@/components/Interface/Modal/Modals/ModalCreateShipDesign/ShipDesignComponent.vue";
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const shipDesignStore = useShipDesignCreationStore()

/**
 * Props
 */
const props = defineProps({
  componentCode: {
    type: String,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

/**
 * Variables
 */
const component = assetStore.findShipComponentByCode(props.componentCode)

</script>

<template>
  <ShipDesignComponent :component="component">
    <ActionButton
        @click="shipDesignStore.removeComponent(props.index)"
        :full-width="true">
      <span v-if="component.group === 'frame'">Reset Design</span>
      <span v-else>Remove</span>
    </ActionButton>
  </ShipDesignComponent>
</template>

<style scoped lang="scss">

</style>