<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import ShipStatIcons from "@/components/Objects/IconsWithCounters/ShipStatIcons.vue";
import ShipDetailsTabs from "@/components/Sections/Command/Ships/ShipDetails/ShipDetailsTabs.vue";
import Position from "@/components/Interface/Position.vue";
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

/**
 * Props
 */
const props = defineProps({
  shipId: {
    type: Number,
    required: true
  }
});

/**
 * Stores
 */
const shipStore = useShipStore()
const gameStore = useGameStore()


/**
 * Computed
 */
const shipData = computed(() => {
  return shipStore.findShipById(props.shipId ?? 0)
})

</script>

<template>
  <div v-if="props.shipId !==0 && shipData">
    <span class="shipHeader flex flex-col justify-between bg-cover rounded" :style="'background-image: url(' + gameStore.cdn + 'images/ship/' + shipData.type + '.webp)'">
      <span class="p-2 text-2xl flex justify-between w-full items-center">
        <span class="flex items-center">
          <ModalTrigger type="editShipName" :data="{shipId: props.shipId}">
            {{ shipData.shipName }}
          </ModalTrigger>
          <Position :x="shipData.xPos" :y="shipData.yPos" class="ms-1"/>
        </span>
      </span>
    </span>
    <div class="pt-2">
      <div class="grid grid-cols-4 lg:grid-cols-7 gap-1">
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="hit_points" :amount="shipData.healthPercentage" :percentage="true"/>
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="attack_against_ships" :amount="shipData.attackShips" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="attack_against_planets" :amount="shipData.attackPlanets" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="shields" :amount="shipData.shields" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="max_speed" :amount="shipData.maxSpeed" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="scanning_range" :amount="shipData.scanningRange" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="repair" :amount="shipData.repair" />
          </div>
        </div>
      </div>
    </div>
    <ShipDetailsTabs :shipId="props.shipId"></ShipDetailsTabs>
  </div>
</template>

<style scoped lang="scss">
.shipHeader{
  height: 12rem;
  background-size: cover;
  background-position: center;
}
</style>