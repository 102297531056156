<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed } from 'vue'
import { formatShort } from '@/models/formatNumber.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'

const planetStore  = usePlanetStore()
const playerOreStore = usePlayerOreStore()

const maxStorage = computed (() => {
  return planetStore.getTotalStorageCapacity()
})

const percentage = computed (() => {
  let percentage = playerOreStore.getTotalOreCapacity() / maxStorage.value * 100
  if(percentage > 100) {
    percentage = 100
  }
  return Math.ceil(percentage)
})

</script>

<template>
  <div class="px-10 pt-5">
    <div class="flex justify-between mb-1">
      <span class="text-sm font-medium text-white">Storage Capacity</span>
      <span class="text-sm font-medium text-white">Max {{ formatShort(maxStorage) }}</span>
    </div>
    <div class="w-full h-6 rounded bg-gray-800 relative">
      <div
        class="h-6 rounded bg-slate-700 flex items-center justify-center text-white text-xs font-medium"
        :style="'width: ' + percentage + '%'"
      >
        {{ percentage }}%
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">

</style>