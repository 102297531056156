<script setup lang="ts">
import {defineProps} from 'vue';
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore();

const props = withDefaults(defineProps<{
  type: String,
  id?: Number,
  title?: String,
  size?: Number,
}>(), {
  size: 5,
})

</script>

<template>
  <span v-if="type === 'food'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/food.png'" alt="Food" title="Food">
  </span>
  <span v-else-if="type === 'population'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/person.png'" alt="Population" title="Population">
  </span>
  <span v-else-if="type === 'production' || type === 'ship_production_cost'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/production.png'" alt="Planet production" title="Planet production">
  </span>
  <span v-else-if="type === 'science'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/science.png'" alt="Science" title="Science">
  </span>
  <span v-else-if="type === 'credit'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/credit.png'" alt="Credits" title="Credits">
  </span>
  <span v-else-if="type === 'energy'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/energy.png'" alt="Energy" title="Energy">
  </span>
  <span v-else-if="type === 'ore' && id === undefined">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ore.png'" alt="Ore" title="Ore">
  </span>
  <span v-else-if="type === 'ore' && id !== undefined">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ore/' + id + '.png'" alt="">
  </span>
  <span v-else-if="type === 'ore_storage' || type === 'ship_cargo_capacity'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ore_storage.png'" alt="Storage" title="Storage">
  </span>
  <span v-else-if="type === 'energy_storage'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/energy_storage.png'" alt="Energy storage" title="Energy storage">
  </span>
  <span v-else-if="type === 'scanning_range'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/scanning-range.png'" alt="Scanning range" title="Scanning range">
  </span>
  <span v-else-if="type === 'happy'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/goodsmiley.png'" alt="Happy" title="happy">
  </span>
  <span v-else-if="type === 'ship_frame'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/frame.png'" alt="Ship frame" title="Ship frame">
  </span>
  <span v-else-if="type === 'ship_mass'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/mass.png'" alt="Ship mass" title="Ship mass">
  </span>
  <span v-else-if="type === 'ship_max_speed'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/max-speed.png'" alt="Ship speed" title="Ship speed">
  </span>
  <span v-else-if="type === 'ship_engine_thrust'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/thrust.png'" alt="Ship Engine thrust" title="Ship engine thrust">
  </span>
  <span v-else-if="type === 'ship_hit_points'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/hit-points.png'" alt="Ship hit points" title="Ship hit points">
  </span>
  <span v-else-if="type === 'ship_shields'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/shields.png'" alt="Ship shields" title="Ship shields">
  </span>
  <span v-else-if="type === 'ship_repair'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/repair.png'" alt="Ship Repair" title="Ship Repair">
  </span>
  <span v-else-if="type === 'ship_attack_ships'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/attack-against-ships.png'" alt="Ship vs ship" title="Ship vs ship">
  </span>
  <span v-else-if="type === 'ship_attack_planets'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/attack-against-planets.png'" alt="Ship vs planet" title="Ship vs planet">
  </span>
  <span v-else-if="type === 'ship_scanning_range'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/scanning-range.png'" alt="Ship scanning range" title="Ship scanning range">
  </span>
  <span v-else-if="type === 'map_location'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/location.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'defence_shields' || type === 'shields'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/shields.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'defence_attack_ships' || type === 'defense_attack_ships'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/ship/attack-against-ships.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'ore_refinery'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/refinery.png'" alt="Ore Refining" title="Ore Refining">
  </span>
  <span v-else-if="type === 'age'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/redflag.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'settle'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/ship/actions/settle.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'ownPlanet'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/ship/actions/target/own_planet.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'vip'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/vip.png'" :alt="title" :title="title">
  </span>
  <span v-else-if="type === 'soldier'">
    <img :class="'w-' + size + ' h-' + size + ' max-w-' + size + ' inline'" :src="'' + gameStore.cdn + 'images/icons/soldier.png'" :alt="title" :title="title">
  </span>

</template>

<style scoped lang="scss">

</style>