<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { CDN } from '@/models/cdn.ts'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'

const assetStore = useAssetStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const tech = computed(() => {
  return assetStore.findTechById(props.newsItem.contentData.techId)
})


</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.technology(128,tech.code)"
    :toast-text="'You have discovered ' + tech.name + '!'"
  >
    <ModalTrigger type="tech" :data="{'techId': newsItem.contentData.techId }" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.technology(128,tech.code)"
        :header="'You have discovered ' + tech.name + '!'"
      >
        <div>
          {{ tech.name }} now allows you to use the following
        </div>
        <div class="flex">
              <span v-for="benefit in tech.provides" :key="benefit.id">
                <span v-if="benefit.type=='buildings'">
                  <ModalTrigger type="building" :data="{'buildingCode': benefit.code}">
                    <ImageRoundedUrl
                      :url="CDN.building(64,benefit.code)"
                      :size="3"
                      class="mr-2 mt-2"
                    />
                  </ModalTrigger>
                </span>
                <span v-if="benefit.type=='ship_components'">
                  <ModalTrigger type="shipComponent" :data="{'shipComponentCode': benefit.code}">
                    <ImageRoundedUrl
                      :url="CDN.shipComponent(64,benefit.code)"
                      :size="3"
                      class="mr-2 mt-2"
                    />
                  </ModalTrigger>
                </span>
              </span>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>

<!--  <div v-if="props.toast">-->
<!--    <ToastItem>-->
<!--      <template #image>-->
<!--        <ImageRounded-->
<!--          type="tech"-->
<!--          source-size="128"-->
<!--          :file="tech.code"-->
<!--          :size="3"-->
<!--        />-->
<!--      </template>-->
<!--      <template #text>-->
<!--        You have discovered {{ tech.name }}!-->
<!--      </template>-->
<!--    </ToastItem>-->
<!--  </div>-->
<!--  <div v-else>-->
<!--    <ModalTrigger type="tech" :data="{'techId': newsItem.contentData.techId }" >-->
<!--      <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">-->
<!--        You have discovered {{ tech.name }}!-->
<!--      </NewsItemHeader>-->
<!--      <div class="bg-slate-800 p-2 text-subtext flex ">-->
<!--        <div class="flex-shrink pr-2">-->
<!--          <ImageRounded-->
<!--            type="tech"-->
<!--            source-size="128"-->
<!--            :file="tech.code"-->
<!--            :size="6"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="flex-grow ps-1">-->
<!--          <div class="pb-2 flex-col">-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </ModalTrigger>-->
<!--  </div>-->
</template>

<style scoped lang="scss">

</style>