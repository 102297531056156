<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import { useGameStore } from '@/stores/gameStore.ts'
import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import BuildingRequired from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingRequired.vue'
import TechRequired from '@/components/Sections/Planets/PlanetTabs/Generic/TechRequired.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const assetStore = useAssetStore()
const gameStore = useGameStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const dysonSwarmTechId = computed(() => {
  return assetStore.findTechByCode('dyson_swarm').id
})

const status = computed(() => {
  // Check if we have invented the correct tech
  const tech = assetStore.findTechByCode('dyson_swarm');
  if(!gameStore.hasPlayerTech(tech.id)) {
    return 'need-tech';
  }
  // check if we have the correct buildings
  if(!planetStore.hasBuilding(props.planetId, 'dyson_sphere_construction_facility')) {
    return 'need-building';
  }
  // show the progress
  return 'constructing';

})

const requiredOre = computed(() => {
  return assetStore.findOreById(gameStore.player!.dysonSwarmOreId)
})

const help = computed(() => {
  return 'Dyson swarms collect huge amounts of energy from the home star. You will need solar panels build to make fully use of it.' +
      'The swarm consists of 5 parts that need to be constructed, each part requires 1 ' + requiredOre.value.oreName + ' to be build and gives 100 Energy to the planet. Construction starts automatically as soon as the Dyson Swarm Construction facility has been built.'
})

</script>

<template>
  <StandardCard title="Dyson Swarm" :help="help" top-padding="2">
    <TechRequired
      v-if="status === 'need-tech'"
      :techId="dysonSwarmTechId"
      :imageCode="'dyson_swarm'"
      text="To be able to build a Dyson Swarm, you will need to discover it first."
    />
    <BuildingRequired
      v-else-if="status === 'need-building'"
      :buildingCode="'dyson_sphere_construction_facility'"
      text="To be able to build a Dyson Swarm for this planet, you need to build the Dyson Swarm Construction Facility first, once it has been built on this planet Construction will start automatically."
    />

    <div
      v-else-if="status === 'constructing'"
      class="FSimpleCard grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 mt-1 gap-2"
    >
      <div
        v-for="index in 5"
        :key="index"
        class="flex flex-col items-center justify-center"
      >
        <div class="">
          <img
            :src="CDN.interface(256, 'dyson_swarm')"
            :class="planetData.dysonSwarmsBuilt >= index ? 'rounded' : 'grayscale rounded opacity-50'"
          />
          <div class="bg-slate-900 p-2 mt-2 w-full text-center rounded flex justify-center">
            <template v-if="planetData.dysonSwarmsBuilt >= index">
              <GenericIconWithColoredNumber :amount="100" type="energy"/>
            </template>
            <template v-else>
              <GenericIconWithColoredNumber :amount="1" :type="'ore'+gameStore.player?.dysonSwarmOreId"/>
            </template>
          </div>
        </div>
      </div>
    </div>
</StandardCard>
</template>

<style scoped lang="scss">

</style>
