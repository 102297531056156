<script setup lang="ts">

import GFBalanceSheetDetailsPlanets
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsPlanets.vue'
import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import GFBalanceSheetDetailsMinisters
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsMinisters.vue'
import GFBalanceSheetDetailsOreTrade
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsOreTrade.vue'
import GFBalanceSheetDetailsShipMaintenance
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsShipMaintenance.vue'
import GFBalanceSheetDetailsSpies
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsSpies.vue'
import GFBalanceSheetDetailsVips
  from '@/components/Sections/Government/Finances/BalanceSheetDetails/GFBalanceSheetDetailsVips.vue'

const playerCreditStore = usePlayerCreditStore()

</script>

<template>
  <GFBalanceSheetDetailsPlanets v-if="playerCreditStore.balanceSheetDetails === 'planets'"/>
  <GFBalanceSheetDetailsMinisters v-if="playerCreditStore.balanceSheetDetails === 'ministers'"/>
  <GFBalanceSheetDetailsOreTrade v-if="playerCreditStore.balanceSheetDetails === 'oreTrades'"/>
  <GFBalanceSheetDetailsShipMaintenance v-if="playerCreditStore.balanceSheetDetails === 'shipMaintenance'"/>
  <GFBalanceSheetDetailsSpies v-if="playerCreditStore.balanceSheetDetails === 'spies'"/>
  <GFBalanceSheetDetailsVips v-if="playerCreditStore.balanceSheetDetails === 'vips'"/>
</template>

<style scoped lang="scss">

</style>