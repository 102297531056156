<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'

/**
 * Stores / models
 */
const assetStore = useAssetStore()

/**
 * Props
 */
const props = defineProps({
  buildingCode: {
    type: String,
    required: true
  }
});

/**
 * Computed
 */
const buildingData = computed(() => assetStore.findBuildingByCode(props.buildingCode)!)

const productionObject = computed(() => assetStore.findProjectObjectByBuildingCode(props.buildingCode)!)

const output = computed(() => {
  return buildingData.value.effects.filter((effect: { bonus: number; type: string }) => effect.bonus > 0);
});

const operationCost = computed(() => {
  return buildingData.value.effects.filter((effect: { bonus: number; type: string }) => effect.bonus < 0);
});



const title = computed(() => {
  return '<div class="flex justify-between"><div>' + buildingData.value.buildingName + '</div><div class="pr-2 flex "><img src="' + CDN.icon('planet') + '" ></div></div>'
})


</script>

<template>
  <StandardCard :title="title" top-padding="0" bottom-padding="2">
    <div class="flex">
      <img :src="CDN.building(128, buildingData.buildingCode)"
           alt="Technology"
           class="shrink-0 rounded w-32 h-32 object-cover">
      <div class="ps-2 w-full  flex flex-col justify-between">
        <div>
          {{ buildingData.buildingDescription }}
        </div>

        <div>
          <div class="justify-start hidden lg:flex 2xl:hidden">
            <div class="pt-2">
              <NamedNumbers v-if="operationCost.length>0" text="Operating cost">
                <template v-for="effect in operationCost">
                  <GenericIconWithColoredNumber :amount="effect.bonus" :type="effect.type"/>
                </template>
              </NamedNumbers>
            </div>
          </div>
          <div class="flex justify-between flex-col lg:flex-row">
            <div class="pt-2">
              <NamedNumbers text="Construction"><GenericIconWithNumber :amount="productionObject.productionCost" type="production"/></NamedNumbers>
            </div>
            <div class="pt-2  block lg:hidden 2xl:block">
              <NamedNumbers v-if="operationCost.length>0" text="Operating cost">
                <template v-for="effect in operationCost">
                  <GenericIconWithColoredNumber :amount="effect.bonus" :type="effect.type"/>
                </template>
              </NamedNumbers>
            </div>
            <div class="pt-2">
              <NamedNumbers v-if="output.length>0" text="Output">
                <template v-for="effect in output">
                  <GenericIconWithColoredNumber :amount="effect.bonus" :type="effect.type"/>
                </template>
              </NamedNumbers>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>