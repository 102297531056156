<script setup lang="ts">

import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore'
import ModalCloseButton from '@/components/Elements/Buttons/ModalCloseButton.vue'

const props = defineProps({
  title: String
});

const gameStore = useGameStore()

const closeModal = () => {
  gameStore.modalType = ''
}

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    closeModal()
  }
})

</script>

<template>
  <div
      class="fixed bg-black bg-opacity-70 w-full h-full top-0 left-0 z-50"
      @click="closeModal()">
    <div
        class="flex overflow-y-auto overflow-x-hidden fixed justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mx-auto model-width-lg">
      <div class="relative p-1 md:p-3 xl:w-4/5 w-full max-h-full" @click.prevent.stop>
        <div class="relative rounded-lg shadow bg-slate-900">
          <div class="flex items-center justify-between p-3 md:p-3 border-b rounded-t border-slate-700 bg-slate-800 ">
            <h3 class="text-slate-300 text-base">
              {{ props.title }}
            </h3>
            <ModalCloseButton @click.stop="closeModal">X</ModalCloseButton>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>



<style scoped lang="scss">

</style>