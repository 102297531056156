<template>
  <StandardCard title="Balance History">
    <div class="chart-container">
      <Bar
        id="credit-history"
        :options="chartOptions"
        :data="chartData"
      />
    </div>
  </StandardCard>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { onMounted, ref, watch } from 'vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { StandardCard, Bar },
  setup() {
    const chartData = ref({
      labels: [],
      datasets: []
    });

    const gameStore = useGameStore()

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'x',
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            color: '#475569',
            align: 'start' // Align ticks to the left
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
            color: '#475569'
          },
          ticks: {
            color: '#475569'
          }
        }
      },
      layout: {
        padding: {
          left: 0 // Align bars to the left
        }
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#475569', // Set the legend text color to green (Tailwind's text-green-500)
            font: {
              size: 14 // Optional: Adjust font size
            }
          }
        }
      },
      animation: false
    };

    const loadChartData = () => {
      const backend = new Backend();
      backend.getFinancialIncomeChartData().then((response) => {
        chartData.value = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: response.data.map(value =>
                value >= 0 ? '#22c55e' : '#ef4444'
              ),
              categoryPercentage: 1, // Adjust bar width
              barPercentage: 0.8 // Adjust bar width
            }
          ]
        };
      });
    };

    onMounted(() => {
      loadChartData();
    });

    watch(() => gameStore.turn, () => {
      loadChartData()
    });

    return {
      chartData,
      chartOptions
    };
  }
};
</script>

<style>
.chart-container {
  width: 100%;
  height: 20rem; /* Fixed height to prevent resizing issues */
}
</style>
