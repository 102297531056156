<script setup lang="ts">
import { useGameStore } from '@/stores/gameStore.ts'
import BaseMultiRowAnimation from '@/components/Elements/Animations/BaseMultiRowAnimation.vue'

const gameStore = useGameStore()

const props = defineProps({
  size: {
    type: Number,
    required: true,
    validator: (value: number) => {
      return [32,64,96,128].includes(value);
    }
  },
  time: {
    type: Number,
    required: false,
    default: 12
  }
})


</script>

<template>
  <BaseMultiRowAnimation :size="size" :rows="20" :cols="12" :url="gameStore.cdn + `images/tests/${props.size}.webp`" />
</template>

<style scoped lang="scss">
</style>