<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed, defineProps } from 'vue'
import MenuItem from '@/components/Sections/Planets/PlanetTabs/StatsTab/MenuItem.vue'
import Content from '@/components/Sections/Planets/PlanetTabs/StatsTab/Content.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId))

</script>

<template>
  <div class="flex pt-2" style="max-width: 40rem;">
    <div class="flex-shrink-0 basis-30 me-1">
      <!--- Population -->
      <MenuItem type="food" :amount="Number(planetData?.populationGrowthPerTurn)"></MenuItem>
      <MenuItem type="production" :amount="Number(planetData?.productionPerTurn)"></MenuItem>
      <MenuItem type="science" :amount="Number(planetData?.sciencePerTurn)"></MenuItem>
      <MenuItem type="credit" :amount="Number(planetData?.creditsPerTurn)"></MenuItem>
      <!--- Resources -->
      <MenuItem type="energy" :amount="Number(planetData?.energyPerTurn)"></MenuItem>
      <MenuItem type="energy_storage" :amount="Number(planetData?.energyStorageCapacity)"></MenuItem>
      <MenuItem type="ore_storage" :amount="Number(planetData?.oreStorageCapacity)"></MenuItem>
      <MenuItem type="primary_ore" :oreId="planetData?.primaryOreId" :amount="Number(planetData?.primaryOreExtraction)"></MenuItem>
      <MenuItem type="secondary_ore" :oreId="planetData?.secondaryOreId" :amount="Number(planetData?.secondaryOreExtraction)"></MenuItem>
      <!--- Defense -->
      <MenuItem type="scanning_range" :amount="Number(planetData?.scanRange)"></MenuItem>
      <MenuItem type="defense_attack_ships" :amount="Number(planetData?.attackAgainstShips)"></MenuItem>
      <MenuItem type="shields" :amount="Number(planetData?.shields)"></MenuItem>
    </div>
    <Content :planetId="props.planetId"></Content>
  </div>

</template>

<style scoped lang="scss">

</style>