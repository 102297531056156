<script setup lang="ts">
import {useGameStore} from "@/stores/gameStore.ts";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()

const ore = computed(() => {
  return assetStore.findOreById(gameStore.modalData.oreId)
})

</script>

<template>
  <MediumModel :title="ore.oreName">
    <div class="flex flex-col sm:flex-row text-center sm:text-left">
      <image-rounded-url :size="16" class="m-2 self-center sm:self-start" :url="gameStore.cdn + '/images/icons/ore/' + ore.id + '.png'"/>
      <div class="text-subtext p-2">
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur quam ligula, iaculis sed justo in, egestas sodales odio. Vivamus porttitor placerat turpis in sagittis. Phasellus tincidunt ultrices felis vel sagittis.</div>
        <div class="pt-2 grid grid-cols-2 gap-1">

        </div>
      </div>
    </div>
  </MediumModel>
</template>


<style scoped lang="scss">

</style>