<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'

/**
 * Stores / models
 */
const assetStore = useAssetStore()

/**
 * Props
 */
const props = defineProps({
  shipComponentCode: {
    type: String,
    required: true
  }
});

/**
 * Computed
 */
const shipComponentData = computed(() => assetStore.findShipComponentByCode(props.shipComponentCode)!)

const output = computed(() => {
  const output = []
  for (const effect of shipComponentData.value.effects) {
    if(effect.type !== 'ship_mass' && effect.type !== 'ship_production_cost' ) {
      output.push(effect)
    }
  }
  return output
})

const mass = computed(() => {
  for (const effect of shipComponentData.value.effects) {
    if(effect.type === 'ship_mass' ) {
      return effect.modifier
    }
  }
  return 0
})

const productionCost = computed(() => {
  for (const effect of shipComponentData.value.effects) {
    if(effect.type === 'ship_production_cost' ) {
      return effect.modifier
    }
  }
  return 0
})

const title = computed(() => {
  return '<div class="flex justify-between"><div>' + shipComponentData.value.name + '</div><div class="pr-2 flex "><img src="' + CDN.icon('ship') + '"></div></div>'
})

</script>

<template>
  <StandardCard :title="title" top-padding="0" bottom-padding="2">
    <div class="flex">
      <img :src="CDN.shipComponent(128, shipComponentData.code)"
           alt="Technology"
           class="shrink-0 rounded w-32 h-32 object-cover">
      <div class="ps-2 w-full  flex flex-col justify-between">
        <div>
          {{ shipComponentData.description }}
        </div>

        <div>
          <div class="justify-start hidden lg:flex 2xl:hidden">
            <div class="pt-2">
              <NamedNumbers v-if="productionCost>0" text="Construction"><GenericIconWithNumber :amount="productionCost" type="production"/></NamedNumbers>
            </div>
          </div>
          <div class="flex justify-between flex-col lg:flex-row">
            <div class="pt-2 block lg:hidden 2xl:block">
              <NamedNumbers v-if="productionCost>0" text="Construction"><GenericIconWithNumber :amount="productionCost" type="production"/></NamedNumbers>
            </div>
            <div class="pt-2">
              <NamedNumbers v-if="mass>0" text="Mass">
                  <GenericIconWithColoredNumber :amount="mass" type="mass"/>
              </NamedNumbers>
            </div>
            <div class="pt-2">
              <NamedNumbers v-if="output.length>0" text="Effect">
                <div class="flex">
                  <template v-for="(effect, index) in output" :key="index">
                    <GenericIconWithColoredNumber
                      :amount="effect.modifier"
                      :type="effect.type"
                      :class="{'ml-3': index > 0}"
                    />
                  </template>
                </div>
              </NamedNumbers>
            </div>
          </div>
        </div>

      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>