<script setup lang="ts">
import * as yup from 'yup'
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {computed, reactive} from "vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormSubmit from "@/components/Interface/Modal/Forms/FormSubmit.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

// Get the planet data
const gameStore = useGameStore()
const shipStore = useShipStore()
const shipData = computed(() => shipStore.findShipById(gameStore.modalData.shipId))

const backend = new Backend()
const formProcess = new FormProcess()


// Define the validation schema
const schema = yup.object().shape({
  ship_name: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(32, () => `Do not use more than 32 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  ship_name: shipData.value.shipName
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  ship_name: ''
});


// Handle form submission
const submit = () => {
  schema.validate(formValues)
    .then(() => {
      formProcess.resetFormErrors(formErrors)
      backend.updateShipName(gameStore.modalData.shipId, formValues.ship_name)
        .then(() => formProcess.processBackendSuccessResponse())
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
    })
    .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}

</script>

<template>
  <SmallModel title="Edit">
    <form method="post" @submit.prevent="submit" class="pl-4 pr-4 pt-4">
      <FormInput
          label="Ship name"
          v-model="formValues.ship_name"
          :error="formErrors.ship_name"
      />
      <div class="flex justify-between py-2">
        <FormGenericError :error="formErrors.generic"/>
        <ActionButton class="">Edit</ActionButton>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>