<script setup lang="ts">

import GFBalanceSheet from '@/components/Sections/Government/Finances/Elements/GFBalanceSheet.vue'
import GFBalance from '@/components/Sections/Government/Finances/Elements/GFBalance.vue'
import GFCreditHistory from '@/components/Sections/Government/Finances/Elements/GFCreditHistory.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()

</script>

<template>
  <div>
    <div class="pr-0 md:pr-2">
      <img :src="gameStore.cdn + 'images/interface/finances.webp'" alt="Finances" class="rounded">
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 pt-2">
      <div>
        <GFBalanceSheet></GFBalanceSheet>
      </div>
      <div class="pr-2">
        <GFBalance></GFBalance>
        <GFCreditHistory></GFCreditHistory>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>