<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const troops = computed(() => {

  // Calculate the percentage of the planet's hitpoints
  const percentage = Math.round((planetData.value.currentHitPoints / planetData.value.maxHitPoints) * 100);

  let color = 'text-green-500';
  if(percentage < 50 && percentage > 25) {
    color = 'text-yellow-500';
  } else if(percentage <= 25) {
    color = 'text-red-500';
  }

  //Get the number of troops icons, one for each 100 hitpoints
  const troopsIcons = Math.floor(planetData.value.currentHitPoints / 100);

  //Get the number of pending troops icons, one for each 100 hitpoints
  const totalTroopsIcons = Math.floor(planetData.value.maxHitPoints / 100);
  const pendingTroopsIcons = totalTroopsIcons - troopsIcons;

  return {
    troopsIcons: troopsIcons,
    pendingTroopsIcons: pendingTroopsIcons,
    percentage: percentage,
    color: color
  }
})


</script>

<template>
  <StandardCard title="Troops" help="Troops are your last line of defense, as soon as all your troops have been killed the enemy will become the new owner of the planet. You gain troops automatically each week, and the maximum number of troops grows with the population size." top-padding="2">
    <div class="flex flex-wrap justify-center rounded pb-2 px-2 pt-1">
      <template v-for="index in troops.troopsIcons" :key="index">
        <GenericIcon type="soldier" class="pr-0.5 pt-0.5 flex-shrink-0"/>
      </template>
      <template v-for="index in troops.pendingTroopsIcons" :key="index">
        <GenericIcon type="soldier" class="grayscale opacity-50 pr-0.5 pt-0.5"/>
      </template>
    </div>
    <div class="text-subtext rounded p-2 mt-2 text-center">
      <span :class="troops.color">{{ planetData.currentHitPoints }}</span> out of <span class="text-slate-300">{{ planetData.maxHitPoints }}</span> troops trained.
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">
</style>
