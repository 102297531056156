<script setup lang="ts">

import MapSideBarTabs from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/MapSideBarTabs.vue'
import { useMapStore } from '@/stores/mapStore.ts'

const mapStore = useMapStore();

const toggleSidebar = (tab:string) => {
  if(mapStore.mapSideBarVisible) {
    if(mapStore.mapSideBarTab === tab) {
      mapStore.mapSideBarVisible = false;
      mapStore.mapSideBarTab = '';
    } else {
      mapStore.mapSideBarTab = tab;
    }
  } else {
    mapStore.mapSideBarVisible = true;
    mapStore.mapSideBarTab = tab;
  }
}

</script>

<template>
<div :class="{
  'sidebar absolute z-30 flex transition-all duration-300':true,
  'sidebar-open':mapStore.mapSideBarVisible,
  'sidebar-closed':!mapStore.mapSideBarVisible
}">
  <div class="flex-grow bg-slate-900 overflow-x-auto">
    <MapSideBarTabs v-if="mapStore.mapSideBarVisible" />
  </div>
  <div class="flex-shrink h-full flex flex-col justify-evenly">
    <div class="border-l border-slate-700 md:flex-grow pt-6 md:pt-0"></div>
    <div :class="{
      'p-1 rounded-br rounded-tr border-r [writing-mode:vertical-lr] border-slate-700 border-b border-t cursor-pointer w-7':true,
      'bg-slate-700':mapStore.mapSideBarTab === 'planets',
      'bg-slate-800':mapStore.mapSideBarTab !== 'planets'
    }" @click="toggleSidebar('planets')">
      <div class="py-1 pt-1">Planets</div>
    </div>
    <div :class="{
      'mt-2 p-1 rounded-br rounded-tr border-r [writing-mode:vertical-lr] border-slate-700 border-b border-t cursor-pointer w-7':true,
      'bg-slate-700':mapStore.mapSideBarTab === 'fleets',
      'bg-slate-800':mapStore.mapSideBarTab !== 'fleets'
    }" @click="toggleSidebar('fleets')">
      <div class="py-1 pt-1">Fleets</div>
    </div>
    <div class="border-l border-slate-700 flex-grow"></div>
  </div>
</div>
</template>

<style scoped lang="scss">
.sidebar {
  width: 43rem;
  height: calc(100vh - 4rem - var(--chat-height));
}
@media (max-width: 500px) {
  .sidebar {
    max-width: 25rem;
    height: calc(100vh - 4rem - var(--chat-height));
  }
}


.sidebar-open {
  left:0;
}
.sidebar-closed {
  left:-41.3rem;
}

@media (max-width: 500px) {
  .sidebar-closed {
    left: -23.3rem;
  }
}

</style>