<script setup lang="ts">

import type PlayerOreTrade from '@/types/PlayerOreTrade.ts'
import { type PropType } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { formatShort } from '@/models/formatNumber.ts'

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  oreTrade: {
    type: Object as PropType<PlayerOreTrade>,
    required: true
  }
})

const gameStore = useGameStore()


</script>

<template>
<div class="p-1 flex justify-start flex-shrink-0">
  <img :src="gameStore.cdn + 'images/icons/ore/' + props.oreTrade.toOreId + '.png'" style="width:1.7rem;height:1.7rem;" alt="" class="me-2">
  <div class="self-center">{{ formatShort(oreTrade.quantity) }}</div>
</div>
</template>

<style scoped lang="scss">

</style>