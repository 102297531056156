<script setup lang="ts">
import * as yup from 'yup'
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {reactive} from "vue";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'

/**
 * Stores / models
 */
const shipDesignStore = useShipDesignCreationStore()
const backend = new Backend()
const formProcess = new FormProcess()


/**
 * Functions
 */
// Define the validation schema
const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(32, () => `Do not use more than 32 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  name: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  name: ''
});

// Handle form submission
const submit = () => {
  schema.validate(formValues)
    .then(() => {
      formProcess.resetFormErrors(formErrors)
      backend.createShipDesign(formValues.name, shipDesignStore.getComponentCodes)
        .then(() => {
          formProcess.processBackendSuccessResponse()
          shipDesignStore.resetDesign()
        })
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
    })
    .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}

</script>

<template>
  <SmallModel title="Save Design">
    <form method="post" @submit.prevent="submit" class="pl-4 pr-4 pt-4">
      <FormInput
          label="Design name"
          v-model="formValues.name"
          :error="formErrors.name"
      />
      <div class="flex justify-between py-2">
        <FormGenericError :error="formErrors.generic"/>
        <ActionButton class="">Save</ActionButton>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>