<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import { useGameStore } from '@/stores/gameStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import Backend from '@/models/backend.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const gameStore = useGameStore()

/**
 * Function
 */
const toggleAbandonPlanet = () => {

  if(planetData.value.abandon === 0) {
    gameStore.modal2Type = 'confirmationDelete';
    gameStore.modal2Data = {
      title: 'Are you sure?',
      message: 'Are you sure you want to abandon this planet?',
      actionText: 'Yes',
      cancelText: 'No',
      action: () => {
        const backend = new Backend();
        backend.abandonPlanet(props.planetId).then(() => {
          gameStore.setTinySuccessToastMessage('Planet is set to be abandoned.')
        })
      },
    };
  } else {
    const backend = new Backend();
    backend.abandonPlanet(props.planetId).then(() => {
      gameStore.setTinySuccessToastMessage('Abandonment of planet has been stopped.')
    })
  }
}

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)


</script>

<template>
  <StandardCard title="Abandon Planet" help="Ordering your population to abandon your planet will take 1 week per population this planet has. Once the population hits zero, the planet will become uninhabited and anyone can settle it again." top-padding="2">
  <div class="flex justify-evenly">
    <div v-if="planetData.abandon === 1" class="flex justify-center">
      <div class="p-2 rounded text-center flex flex-col">
        <div class="pb-3 text-slate-300">Abandonment Time</div>
        <div class="text-2xl font-bold text-red-500">{{planetData.population}} Weeks</div>
      </div>
    </div>
    <div class="flex items-center p-4">
      <ActionButton v-if="planetData.abandon === 0" @click="toggleAbandonPlanet">Abandon Planet</ActionButton>
      <ActionButton v-if="planetData.abandon === 1" @click="toggleAbandonPlanet">Stop Abandoning Planet</ActionButton>
    </div>
  </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>
