<script setup lang="ts">

import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import ModalCloseButton from '@/components/Elements/Buttons/ModalCloseButton.vue'

const props = defineProps({
  title: String
});

const gameStore = useGameStore()

const closeModal = () => {
  gameStore.modal2Type = ''
}

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    closeModal()
  }
})

</script>

<template>
  <div
    class="fixed bg-black bg-opacity-50 w-full h-full top-0 left-0"
    @click="closeModal()"
  >
    <div
      class="flex overflow-x-hidden fixed justify-center items-center w-full md:inset-0 h-max-[calc(100%-1rem)]"
    >
      <div
        class="relative p-3 sm:w-1/3 max-w-md max-h-full mx-auto"
        @click.stop
      >
        <div class="relative rounded-lg shadow bg-slate-900">
          <div
            class="flex items-center justify-between p-3 md:p-3 border-b rounded-t bg-slate-800 border-slate-700"
          >
            <h3 class="text-xl font-semibold text-subtext ">
              {{ props.title }}
            </h3>
            <ModalCloseButton @click.stop="closeModal">X</ModalCloseButton>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>



</template>



<style scoped lang="scss">

</style>