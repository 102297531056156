<script setup lang="ts">

import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import { computed } from 'vue'
import DysonSwarm from '@/components/Sections/Resources/Elements/DysonSwarm.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const oreConversionStore = useOreConversionStore()
const gameStore = useGameStore()

const data = computed(() => {
  return oreConversionStore.getOreVisualDataFromOreId(gameStore.player?.dysonSwarmOreId)
})

</script>

<template>
  <DysonSwarm
    :ore="data.ore"
    :processed="oreConversionStore.data.dysonSwarm"
    :top="90"
    :left="data.left"
    :height="5"
    :width="8"
    :conveyor-type="data.conveyorType"
    :debug="false"
  ></DysonSwarm>
</template>

<style scoped lang="scss">

</style>