<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import { useGameStore } from '@/stores/gameStore.ts'
import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import PlanetAnimation from '@/components/Elements/Animations/Planet/PlanetAnimation.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import BuildingRequired from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingRequired.vue'
import TechRequired from '@/components/Sections/Planets/PlanetTabs/Generic/TechRequired.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const assetStore = useAssetStore()
const gameStore = useGameStore()
const oreConversionStore = useOreConversionStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const terraformOre = computed(() => {
  const data = oreConversionStore.getLevel2OreVisualDataFromPlanetType(Number(gameStore.player!.homeWorldPlanetType))
  return assetStore.findOreById(data.ore)
})

const planetTypeName = computed(() => {
  return assetStore.findPlanetTypeById(Number(planetData.value.type))?.name
})

const status = computed(() => {
  // Check if the planet is already a paradise
  if(Number(planetData.value.type) === gameStore.player!.homeWorldPlanetType) {
    return 'terraformed';
  }
  // Check if we have invented the correct tech
  const tech = assetStore.findTechByCode('terraforming');
  if(!gameStore.hasPlayerTech(tech.id)) {
    return 'need-tech';
  }
  // check if we have the correct buildings
  if(!planetStore.hasBuilding(props.planetId, 'terraforming_command_center')) {
    return 'need-building';
  }
  // show the terraforming progress
  return 'terraforming';
})

const terraformerTechId = computed(() => {
  return assetStore.findTechByCode('terraforming').id
})

const terraformingPercentage = computed(() => {
  return planetData.value?.terraformProgress / 25 * 100
})

const help = computed(() => {
  return 'Terraforming allows you to change the type of a planet to match your homeworld, which will make this planet more habitable/productive.\n' +
    '      Once a terraformer is build, it will start to consume 1 ' + terraformOre.value.oreName + ' per week and it will take 25 weeks to complete the terraforming process.'
})

</script>

<template>
  <StandardCard title="Terraformer" :help="help" :top-padding="2">
    <div class="flex flex-col">
      <div v-if="status === 'terraformed'">
        <div class="text-subtext">This planet is of the <span class="text-green-500">{{ planetTypeName }}</span> type, which is perfect for you.</div>
      </div>
      <TechRequired
        v-else-if="status === 'need-tech'"
        :techId="terraformerTechId"
        :imageCode="'terraforming'"
        text="To be able to terraform this planet, you need to discover the Terraforming technology first."
      />
      <BuildingRequired
        v-else-if="status === 'need-building'"
        :buildingCode="'terraforming_command_center'"
        text="To be able to terraform this planet, you need to build the Terraforming Command Center first, once it has been built on this planet Terraforming will start automatically."
       status/>
      <div v-else-if="status === 'terraforming'">
        <div class="flex justify-between items-center text-center">
          <div class="p-2">
            <PlanetAnimation :size="96" :type="Number(planetData.type)"/>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-300 pb-2">Terraforming in progress</div>
            <div class="px-1 py-3">
              <SimpleProgressBar :percentage="terraformingPercentage"/>
            </div>
            <div class="pt-2 text-slate-300"> {{ (25 - planetData?.terraformProgress) }} {{ terraformOre?.oreName }} required</div>
          </div>
          <div class="p-2">
            <PlanetAnimation :size="96" :type="Number(gameStore.player!.homeWorldPlanetType)"/>
          </div>
        </div>

      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>
