<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import { computed, onMounted, type PropType, ref, watch } from 'vue'
import type Ore from '@/types/Ore.ts'
import { formatShort } from '@/models/formatNumber.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'
import Backend from '@/models/backend.ts'
import { debounce } from 'lodash'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'

const gameStore = useGameStore()
const playerOreStore = usePlayerOreStore()

const props = defineProps({
  ore: {
    type: Object as PropType<Ore>,
    required: true
  },
  sourceOreId: {
    type: Number,
    required: true
  }
})

const amount = ref(0)

const stored = computed(() => {
  return playerOreStore.data[props.ore.code]
})

const setOreTrade = () => {
  const backend = new Backend()
  backend.setOreTrade(props.sourceOreId, props.ore.id, amount.value).then(() => {
    gameStore.setTinySuccessToastMessage('Trade will take effect next "Week"')
  })
}

watch (() => amount.value, () => {
  //if the amount is text, set it to 0
  if(isNaN(amount.value)) {
    amount.value = 0
  }
  if(amount.value < 0) {
    amount.value = 0
  }
  if(amount.value > 1000) {
    amount.value = 1000
  }
})

watch(() => playerOreStore.tradeData, () => {
  const data = playerOreStore.getPlayerOreTrade(props.sourceOreId, props.ore.id)
  if(data) {
    amount.value = data.quantity
  }
},{deep:true})

onMounted(() => {
  const data = playerOreStore.getPlayerOreTrade(props.sourceOreId, props.ore.id)
  if(data) {
    amount.value = data.quantity
  }
})

</script>

<template>
  <div :class="{
    'flex flex-col items-center justify-center text-center border rounded-md bg-slate-700 border-slate-600  p-2' : true,
    'border-slate-600': amount == 0,
    'border-green-500': amount > 0
    }">
    <div class="pb-2 text-white">{{ ore.oreName }}</div>
    <img alt="" style="width: 5rem;" :src="gameStore.cdn + '/images/icons/ore/' + ore.id + '.png'">
    <div v-if="stored>0" class="p-2 text-green-500">
      {{ formatShort(stored) }}
    </div>
    <div v-else class="p-2">
      -
    </div>
    <div class="border-b border-b-slate-600 w-full"></div>
    <div class="p-2 h-9" v-if="amount!=0"><Icon type="credit" :size="4"></Icon><IconCount :amount="-amount*ore.tradeConversionCost"></IconCount></div>
    <div class="p-2 h-9" v-else>No trade</div>
    <input type="text" class="w-20" v-model="amount" @change="setOreTrade">
  </div>
</template>

<style scoped lang="scss">
input {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}

</style>