<script setup lang="ts">

import Position from '@/components/Interface/Position.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps<{
  shipActionData: {
    action: string;
    targetX: number;
    targetY: number;
    targetPlayer: number;
    targetShip: number;
    data: {
      name: string;
      type: number;
      typeVariantId: number;
      targetPlayer: number;
    }
  },
  eta: number
}>();

</script>

<template>
  <StandardCard top-padding="0" bottom-padding="1">
    <div class="flex justify-between text-subtext w-full">
      <div class="flex gap-4">
        <GenericIcon type="cmd_settle"/>
        <div class="flex items-center">
          <div class="me-1">Settle <span class="">{{ shipActionData.data.name }}</span></div><Position :x="props.shipActionData.targetX" :y="props.shipActionData.targetY"/>
        </div>
      </div>
      <div>
        {{ eta }} days
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>