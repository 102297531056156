<script setup lang="ts">

</script>

<template>
  <div>
    <div class="content-header my-1">Balance Sheet VIPS Details</div>
    Todo
  </div>
</template>

<style scoped lang="scss">

</style>