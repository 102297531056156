<script setup lang="ts">

import { onMounted, watch } from 'vue'
import { useLeaderBoardStore } from '@/stores/leaderBoardStore.ts'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { useMapStore } from '@/stores/mapStore.ts'
import { firstCamel } from '@/models/tools.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Stores / models
 */
const leaderBoardStore = useLeaderBoardStore()
const playerRelationStore = usePlayerRelationStore()
const gameStore = useGameStore()
const mapStore = useMapStore()
const assetStore = useAssetStore()

/**
 * Functions
 */
const filters = (players) => {
  return Array.from(players.values())
}

const loadPlayer = (playerId:number) => {
  if(playerId === gameStore.player?.id) {
    return
  }
  gameStore.modalType = 'requestAudience'
  gameStore.modalData = {
    relation: playerRelationStore.findRelationByPlayerId(playerId)
  }
}

/**
 * Lifecycle hooks
 */
onMounted(() => {
  watch(
    () => mapStore.mapLoaded, // Reactive property to watch
    (isLoaded) => {
      if (isLoaded) {
        // Start building the leaderboard from map data
        leaderBoardStore.generateLeaderBoard();
      }
    },
    { immediate: true } // Trigger immediately if already true
  );
})

/**
 * Watchers
 */
watch(() => playerRelationStore.relations, () => {
  if(mapStore.mapLoaded) {
    leaderBoardStore.generateLeaderBoard();
  }
}, { deep: true })

watch(() => gameStore.player?.currentTechId, () => {
  if(mapStore.mapLoaded) {
    leaderBoardStore.generateLeaderBoard();
  }
})

</script>

<template>
<div class="p-2 w-full sm:w-1/2">
    <FerionDataTable
      :data="leaderBoardStore.players"
      tableStoreName="leaderboard"
      :selector="false"
      :filters="filters"
      :heightLimit="'h-[calc(100vh-9.4rem)]'"
      :no-result-message="'No data yet...'"
      :click="loadPlayer"
    >
      <!--- Headers --->
      <template #columns="{ sort, sortColumn, sortDirection }">
        <HeaderFixedWidthText width="3" :sort-direction="sortDirection" :sort-column="sortColumn" column="playerId" class="rounded-tl"/>
        <HeaderTextFlex min-width="10" column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <div class="pl-2">Name</div>
        </HeaderTextFlex>
        <HeaderFixedWidthText width="3" :sort-direction="sortDirection" :sort-column="sortColumn" column="country" @sort="sort">

        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="8" :sort-direction="sortDirection" :sort-column="sortColumn" column="relation" @sort="sort">
          <div class="text-center">
            Status
          </div>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="2" :sort-direction="sortDirection" :sort-column="sortColumn" column="otherRequested" @sort="sort">
          <div class="text-center">
            <span
              class="rounded bg-slate-500 text-white text-xs px-1"
            >!</span>
          </div>
        </HeaderFixedWidthText>
        <HeaderFixedWidthIcon width="4" column="planets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <div class="flex justify-center">
            <span class=""><PlanetIcon :size="2" :type="5"></PlanetIcon></span>
          </div>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="4" column="ships" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <div class="flex justify-center">
            <GenericIcon type="ship" class="rounded mt-1"/>
          </div>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthTextNoSort width="20"  class="rounded-tr">
          <div class="flex justify-center">Researching</div>
        </HeaderFixedWidthTextNoSort>
      </template>
      <!--- Rows --->
      <template #rows="{ item }" >
        <CellFixedWidth width="3">
          <div class="flex-col px-2">
            <img :src="'https://galexion.com/cache?url=' + item.profileImage + '&w=50&h=50'" class="w-9 h-9 rounded" alt="Profile image">
          </div>
        </CellFixedWidth>
        <CellFlexWidth min-width="10">
          <div class="flex-col ml-2 ">
            <PlayerName :player-id="item.playerId"/>
          </div>
        </CellFlexWidth>
        <CellFixedWidth width="3">
          <div class="flex-col">
            <img alt="NA" :src="'https://flagcdn.com/32x24/' + item.country + '.webp'" class="h-3 rounded">
          </div>
        </CellFixedWidth>
        <CellFixedWidth width="8" align="center">
          <span class="text-subtext" v-if="item.relation !='self'">{{ firstCamel(item.relation) }}</span>
        </CellFixedWidth>
        <CellFixedWidth width="2">
            <span
              v-if="item.otherRequested !== 'none'"
              class="rounded bg-red-500 text-slate-300 text-xs px-1"
            >!</span>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <span v-if="item.exact">{{ item.planets }}</span>
          <span v-else class="text-subtext">{{ item.planets }}</span>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <span v-if="item.exact">{{ item.ships }}</span>
          <span v-else class="text-subtext">{{ item.ships }}</span>
        </CellFixedWidth>
        <CellFixedWidth width="20">
          <span v-if="item.currentTechId>0">{{ assetStore.findTechById(item.currentTechId)?.name }}</span>
        </CellFixedWidth>
      </template>

    </FerionDataTable>
</div>
</template>

<style scoped lang="scss">

</style>