<script setup lang="ts">

import { computed, defineProps, ref, type Ref } from 'vue'
import PlanetBuildingList from '@/components/Sections/Planets/PlanetTabs/BuildingsTab/PlanetBuildingList.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const assetStore = useAssetStore()

/**
 * Variables
 */
const selectedType: Ref<string> = ref('food');

const list = [
  'food','production','science','credit','energy','energy_storage',
  'ore_storage','scanning_range','defence_attack_ships','defence_shields',
  'ore_refinery','ore'
];

/**
 * Computed
 */
const planetData = computed(() => {
  return planetStore.findPlanetById(props.planetId)!
})

const filteredList = computed(() => {

  const availableTypes = new Map()

  for(let i = 0; i < planetData.value.buildings.length; i++){
    let index = Number(i); // Explicit conversion, though redundant in this case
    const building = assetStore.findBuildingByCode(planetData.value.buildings[index]);
    if (!building) {
      throw new Error(`Building with ID ${planetData.value.buildings[index]} not found.`);
    }
    building.effects.forEach((effect: {type:string,bonus:number}) => {
      if (effect.bonus > 0) {
        availableTypes.set(effect.type, effect.type)
      }
    });
  }

  //filter the list of building types based on the available types
  const newList = []
  for (let i = 0; i < list.length; i++) {
    if (availableTypes.has(list[i])) {
      newList.push(list[i])
    }
  }
  return newList
})

</script>

<template>
  <div class="flex flex-grow pt-2" style="max-width: 40rem;">
    <div v-if="filteredList.length>0" class="flex-shrink-0 basis-12 me-1">
      <template v-for="item in filteredList">
        <div
          @click="selectedType = item"
          :class="{
            ' p-2 mb-1 ps-3 me-1 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': selectedType === item,
            'bg-slate-800 hover:bg-slate-700': selectedType !== item
          }">
          <GenericIcon :type="item" :class="{'grayscale' : selectedType !== item}"/>
        </div>
      </template>
    </div>
    <PlanetBuildingList :planetId="props.planetId" :type="selectedType"/>
  </div>

</template>

<style scoped lang="scss">

</style>
