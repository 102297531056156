import { useGameStore } from '@/stores/gameStore.ts'
import { icons } from '@/assets/icons.ts'
import { techIcons} from '@/assets/techIcons.ts'

class FerionCDN {
  private _gameStore?: ReturnType<typeof useGameStore>;

  private get gameStore() {
    if (!this._gameStore) {
      this._gameStore = useGameStore(); // Initialize the store when accessed
    }
    return this._gameStore;
  }

  technology(size: number, type: string): string {
    return this.gameStore.cdn + '/ferion/images/technologies/' + size + '/' + type + '.webp';
  }

  building(size: number, type: string): string {
    return this.gameStore.cdn + '/ferion/images/buildings/' + size + '/' + type + '.webp';
  }

  ship(size: number): string {
    return this.gameStore.cdn + '/ferion/images/buildings/' + size + '/ship.webp';
  }

  shipComponent(size: number, type: string): string {
    return this.gameStore.cdn + '/ferion/images/shipComponents/' + size + '/' + type + '.webp';
  }

  planet(size: number, type: number|string): string {
    return this.gameStore.cdn + '/ferion/images/planets/' + type + '/' + size + '.webp';
  }

  planetShield(): string {
    return this.gameStore.cdn + '/images/planet/shield.png';
  }

  attributeSide(type:string): string {
    return this.gameStore.cdn + '/ferion/images/attributes/' + type + '/side.webp';
  }

  interface(size: number, type: string): string {
    return this.gameStore.cdn + '/ferion/images/interface/' + type + '/' + size + '.webp';
  }

  icon32(type: string): string {
    return this.gameStore.cdn + '/ferion/images/icons/32/' + type + '.webp';
  }

  icon(type: string): string {
    return icons[this.normalize(type)] || ''; // Return an empty string if the type is not found
  }

  techIcon(type: string): string {
    return techIcons[type] || ''; // Return an empty string if the type is not found
  }

  ministerImage(code:string): string {
    return this.gameStore.cdn + '/ferion/images/ministers/' + code + '.webp';
  }
  ministerVideo(code:string): string {
    return this.gameStore.cdn + '/ferion/videos/ministers/' + code + '.webm';
  }

  normalize(type:string): string
  {
    const replaceList: Record<string, string> = {
      'defense_attack_ships': 'attack_against_ships',
      'attack_against_ships': 'attack_against_ships',
      'defence_attack_ships': 'attack_against_ships',
      'defence_shields': 'shields',
      'ore_refinery': 'refinery',
      'ship_engine_thrust': 'thrust',
      'ship_engine_max_speed': 'max_speed',
      'ship_max_speed': 'max_speed',
      'ship_hit_points': 'hit_points',
      'ship_shields': 'shields',
      'ship_attack_ships': 'attack_against_ships',
      'ship_attack_planets': 'attack_against_planets',
      'ship_repair': 'repair',
      'ship_frame': 'frame',
      'ship_mass': 'mass',
      'ship_scanning_range': 'scanning_range',
      'ship_production_cost': 'production',
      'armor': 'hit_points',
      'weapon': 'attack_against_ships',
      'engine': 'max_speed',
      'scanner': 'scanning_range',
      'special': 'cog'
    };

    return replaceList[type] || type;

  }
}

export const CDN = new FerionCDN();
