<script setup lang="ts">

import { computed } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import PlanetProductionQueuedProductionObject
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Elements/PlanetProductionQueuedProductionObject.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore();
const assetStore = useAssetStore();

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props?.planetId)!);

/**
 * Dynamically compute the queued production objects based on planet data
 **/
const queuedProductionObjects = computed(() => {
  const productionQueue = planetData.value.productionQueue;

  if (!productionQueue) {
    return [];
  }

  // Generate the list of production objects
  return productionQueue.map((queueItem) => ({
    object: assetStore.findProductionObjectById(queueItem['productionObjectId']),
    queueId: queueItem['id'],
    productionShipDesignId: queueItem['productionShipDesignId'],
    productionShipFleetId: queueItem['productionShipFleetId'],
  }));
});

</script>

<template>
  <div v-for="productionObject in queuedProductionObjects" :key="productionObject.object.id">
    <PlanetProductionQueuedProductionObject
      :planet-id="planetData.id"
      :queue-id="productionObject.queueId"
      :production-object-id="productionObject.object.id"
      :production-ship-design-id="productionObject.productionShipDesignId"
      :production-ship-fleet-id="productionObject.productionShipFleetId"
    >
    </PlanetProductionQueuedProductionObject>
  </div>
</template>

<style scoped lang="scss">

</style>