<script setup lang="ts">

import Terraformer from '@/components/Sections/Resources/Elements/Terraformer.vue'
import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'

const oreConversionStore = useOreConversionStore()
const gameStore = useGameStore()

const data = computed(() => {
  return oreConversionStore.getLevel2OreVisualDataFromPlanetType(gameStore.player?.homeWorldPlanetType)
})

</script>

<template>
  <Terraformer
    :ore="data.ore"
    :processed="oreConversionStore.data.terraForming"
    :top="54"
    :left="data.left"
    :height="5"
    :width="8"
    :conveyor-type="data.conveyorType"
    :debug="false"
  ></Terraformer>
</template>

<style scoped lang="scss">

</style>