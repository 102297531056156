<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed, defineProps } from 'vue'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  productionObjectId: {
    type: Number,
    required: true
  },
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const gameStore = useGameStore()
const backend = new Backend()
const assetStore = useAssetStore()

/**
 * Computed
 */
const productionObjectData = computed(() => assetStore.findProductionObjectById(props.productionObjectId))

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

/**
 * Methods
 */
const setProductionObject = () => {

  if(productionObjectData.value.type == 'building') {
    if(props.planetId && productionObjectData.value.objectId) {
      backend.setPlanetsProductionObject([props.planetId], props.productionObjectId,false);
      gameStore.setTinySuccessToastMessage('Building added to production queue');
    }
  } else {
    // Open the build ship modal
    gameStore.modalType = 'planetBuildShip';
    gameStore.modalData = {
      planetIds: [props.planetId]
    }
  }
}

</script>

<template>
  <StandardCard :title="productionObjectData.productionObjectName" topPadding="0" bottomPadding="2">
    <div class="flex flex-col">
      <div class="flex justify-start">
        <div class="flex flex-col flex-shrink-0 ">
          <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
            <img
              :src="CDN.building(128,productionObjectData.objectId)"
              class="rounded h-20"
              alt=""
            />
          </ModalTrigger>
          <img
            v-else
            :src="CDN.ship(128)"
            class="rounded h-20"
            alt=""
          />
        </div>
        <div class="flex flex-col justify-between flex-grow ps-2.5">
          <div class="text-subtext">
            {{ productionObjectData.description }}
          </div>
          <div class="flex justify-between">
            <div class="flex justify-start">
              <div class="flex items-center">
                <BuildingEffects v-if="buildingData" :building="buildingData"/>
              </div>
            </div>
            <ActionButton>
              <GenericIconWithNumber
                v-if="productionObjectData.productionCost>0"
                @click="setProductionObject"
                :amount="productionObjectData.productionCost"
                type="production"
                textColor="text-slate-300"
              />
              <GenericIcon v-else type="production" @click="setProductionObject"/>
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>