<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { computed, defineProps } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import BuildingRequired from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingRequired.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

</script>

<template>
  <StandardCard title="Active Defenses" topPadding="2" help="Active defenses counter attack the invader. Destroying all enemy ships automatically end their attack.">
    <div v-if="planetData.attackAgainstShips===0">
      <BuildingRequired text="Build your first active defense building to better protect your planet." building-code="orbital_laser_array"/>
    </div>
    <div v-else class="flex justify-center">
      <div class="p-2 rounded text-center flex flex-col flex-shrink-0">
        <div class="pb-3 text-slate-300">Attack Strength</div>
        <GenericIconWithColoredNumber :amount="planetData.attackAgainstShips" type="defense_attack_ships" class="self-center"/>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>