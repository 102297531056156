<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import BuildingRequired from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingRequired.vue'
import { computed, defineProps } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

</script>

<template>
  <StandardCard title="Passive Defenses" topPadding="2" help="Passive defenses enhance your Planetary Shields making it more efficient in deflecting attacks. This allows your shields to hold out longer.">
    <div v-if="planetData.shields===0">
      <BuildingRequired
        :buildingCode="'planetary_shields'"
        text="Build Planetary Shields first."
        status/>
    </div>
    <div v-else class="flex justify-center">
      <div class="p-2 rounded text-center flex flex-col flex-shrink-0">
        <div class="pb-3 text-slate-300">Defense Strength</div>
        <GenericIconWithColoredNumber :amount="planetData.shields" type="shields" class="self-center"/>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>