<script setup lang="ts">

import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { formatShort } from '@/models/formatNumber.ts'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true
  },
  textColor: {
    type: String,
    required: false,
    default: 'text-subtext'
  }
});

</script>

<template>
  <div class="flex">
    <GenericIcon :type="props.type"/><span :class="textColor + ' ps-2'">{{ formatShort(amount) }}</span>
  </div>
</template>

<style scoped lang="scss">

</style>