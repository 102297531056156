<script setup lang="ts">

import { computed, defineProps } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'
import ConveyorBT from '@/components/Sections/Resources/Elements/Conveyor/ConveyorBT.vue'
import ConveyorTB from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTB.vue'
import ActiveOreTrade from '@/components/Sections/Resources/Elements/OreTrade/ActiveOreTrade.vue'
import { CDN } from '@/models/cdn.ts'

const gameStore = useGameStore()
const playerOreStore = usePlayerOreStore()

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  conversionOut: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conversionIn: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const oreTrades = computed(() => {
  return playerOreStore.getPlayerOreTradeForOre(props.oreId)
})

// Open the modal for ore conversion
const openModal = () => {
  gameStore.modalType = 'oreTrade'
  gameStore.modalData = props.oreId
}

</script>

<template>
  <div :class="{
    'absolute': true,
    'border': debug
  }"
    :style="'left:' + left + 'rem;top:' + top + 'rem;width:5rem;height:10rem;'">
    <ConveyorTB
      :object-image="CDN.icon('ore' + props.oreId)"
      :conversion="props.conversionOut"
      :width="1"
      :height="6"
      :top="1.5"
      :left="1.25"
      :debug="debug"
    />

    <ConveyorBT
      :object-image="CDN.icon('ore' + props.oreId)"
      :conversion="props.conversionIn"
      :width="1"
      :height="6"
      :top="1.5"
      :left="2.75"
      :debug="debug"
    />
    <div :class="{
            'absolute rounded bg-slate-800 border border-slate-700 hover:border-slate-600':true,
            'opacity-50':debug
          }"
         style="top:6rem;left:0rem;width:5rem;z-index:15"
    >
      <div class="flex-col text-center cursor-pointer max-h-36 overflow-y-auto" @click="openModal">
        <div v-if="oreTrades.length>0">
          <template v-for="trade in oreTrades" :key="trade.fromOreId + '-' + trade.toOreId">
            <ActiveOreTrade :ore-trade="trade" :ore-id="props.oreId"></ActiveOreTrade>
          </template>
        </div>
        <div v-else class="p-2">Trade</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>