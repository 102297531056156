import { defineStore } from 'pinia';
import type PlayerOreTrade from '@/types/PlayerOreTrade.ts'

export const usePlayerOreStore = defineStore('playerOreStore', {
    state: () => ({
        data: {
            storage: 0,
            bezantium: 0,
            radiusant: 0,
            nucloid: 0,
            verrotan: 0,
            boturkoec: 0,
            nagazal: 0,
            formets: 0,
            grindazine: 0,
            perunga: 0,
            veronium: 0,
            blazanter: 0,
            terrilliod: 0,
            karmasas: 0,
            nadzerbat: 0,
            galagores: 0,
            miout: 0,
            ldwarts: 0,
            mankazor: 0,
            ferrosnr6: 0,
            sjampoo: 0,
            texorrium: 0,
            kroketite: 0,
            rabogen: 0,
            dropium: 0,
        },
        tradeData: new Map<string, PlayerOreTrade>(),
    }),
    getters: {},
    actions: {
        setPlayerOreTrade(data: PlayerOreTrade) {
            // Make a key from lower and higher ore id
            const key = data.fromOreId + '-' + data.toOreId;
            this.tradeData.set(key, data);
        },
        getPlayerOreTrade(fromOreId: number, toOreId: number) {
            const key = fromOreId + '-' + toOreId;
            return this.tradeData.get(key);
        },
        getPlayerOreTradeForOre(oreId: number) {
            return Array.from(this.tradeData.values()).filter(
              (value) =>
                value.fromOreId === oreId &&
                value.quantity > 0
            );
        },
        setValue(key: keyof typeof this.data, value: number) {
            this.data[key] = value;
        },
        getTotalOreCapacity() {
            return Object.values(this.data).reduce((acc, val) => acc + val, 0);
        }
    },
});
