<script setup lang="ts">

import {computed, defineProps, onMounted, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import Backend from "@/models/backend";
import { useGameStore } from '@/stores/gameStore'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const gameStore = useGameStore()
const backend = new Backend()

/**
 * Variables
 */
const taxSlider = ref();

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

/**
 * Functions
 */
// Function to update sliders based on the current planet data
const adjustTaxSlider = () => {
  backend.updatePlanetTax(props.planetId, taxSlider.value).then(
    () => {
      gameStore.setTinySuccessToastMessage('Taxation updated');
    }
  )
};

/**
 * Watchers
 */
// Watch for changes in planetId and update sliders accordingly
watch(() => props.planetId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    taxSlider.value = planetData.value.taxPercentage || 0;
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well


/**
 * Lifecycle
 */
onMounted(() => {
  taxSlider.value = planetData.value.taxPercentage || 0;
});

</script>

<template>
  <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/taxman.webp'" alt="Taxman" title="Taxman">
  <div class="relative mb-6 flex-grow">
    <input
        type="range"
        id="tax"
        @input="adjustTaxSlider()"
        min="0"
        max="100"
        step="10"
        v-model="taxSlider"
        class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
    <span class="text-subtext absolute start-0 -bottom-6">Taxation: <span class="text-slate-300">{{ planetData.taxPercentage }}%</span></span>
  </div>
</template>

<style scoped lang="scss">
.table-td{
  height: 50px !important;
  width: 50px;
}
</style>
