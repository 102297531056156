import { defineStore } from 'pinia';
import type PlanetQueueAutomation from '@/types/PlanetQueueAutomation.ts';
import Backend from '@/models/backend.ts';

export const usePQAStore = defineStore('PQAStore', {
    state: () => ({
        filters: {
            food: true,
            production: true,
            science: true,
            energy_storage: true,
            ore_storage: true,
            ore: true,
            energy: true,
            credit: true,
            defence_shields: true,
            defence_attack_ships: true,
            scanning_range: true,
        },
        queues: new Map<number, PlanetQueueAutomation>(),
        selectedQueue: 0 as number,
        selectedQueueData: [] as number[],
        typeFilter: 'all' as string,
        selectedModalMobileTab: 'available' as string, // Tabs for the mobile modal (PQA)
    }),
    getters: {
        // Add any getters if needed
    },
    actions: {
        toggleFilter(key: keyof typeof this.filters) {
            this.filters[key] = !this.filters[key];
        },
        updateQueue(data: PlanetQueueAutomation) {
            this.queues.set(data.id, data);
        },
        deleteQueue(id: number) {
            this.queues.delete(id);
        },
        findQueueById(id: number): PlanetQueueAutomation | undefined {
            return this.queues.get(id);
        },
        selectQueue(id: number) {
            const queue = this.queues.get(id);
            if (queue) {
                this.selectedQueue = id;
                this.selectedQueueData = [...queue.queue];
                this.selectedModalMobileTab = 'available';
            } else {
                console.error('Queue not found:', id);
            }
        },
        addToQueue(productObjectId: number) {
            if (!this.isInQueue(productObjectId)) {
                this.selectedQueueData.push(productObjectId);
                this.saveQueue();
            }
        },
        removeFromQueue(index: number) {
            if (index >= 0 && index < this.selectedQueueData.length) {
                this.selectedQueueData = this.selectedQueueData.filter((_, i) => i !== index);
                this.saveQueue();
            }
        },
        isInQueue(productObjectId: number): boolean {
            return this.selectedQueueData.includes(productObjectId);
        },
        moveUp(index: number) {
            if (index > 0) {
                [this.selectedQueueData[index - 1], this.selectedQueueData[index]] =
                  [this.selectedQueueData[index], this.selectedQueueData[index - 1]];
                this.saveQueue();
            }
        },
        moveDown(index: number) {
            if (index < this.selectedQueueData.length - 1) {
                [this.selectedQueueData[index], this.selectedQueueData[index + 1]] =
                  [this.selectedQueueData[index + 1], this.selectedQueueData[index]];
                this.saveQueue();
            }
        },
        moveTop(index: number) {
            if (index > 0) {
                const [item] = this.selectedQueueData.splice(index, 1);
                this.selectedQueueData.unshift(item);
                this.saveQueue();
            }
        },
        moveBottom(index: number) {
            if (index < this.selectedQueueData.length - 1) {
                const [item] = this.selectedQueueData.splice(index, 1);
                this.selectedQueueData.push(item);
                this.saveQueue();
            }
        },
        async saveQueue() {
            const backend = new Backend();
            try {
                await backend.updatePQA(this.selectedQueue, this.selectedQueueData);
            } catch (error) {
                console.error('Failed to save queue:', error);
            }
        },
    },
});
