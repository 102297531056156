<script setup lang="ts">

import {computed} from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

import Backend from '@/models/backend.ts'
import router from "@/router";
import ShipCurrentActions
  from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions/Current/ShipCurrentActions.vue'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import Eta from '@/models/eta.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  fleetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores
 */
const fleetStore = useFleetStore()
const shipActionStore = useShipActionStore()
const gameStore = useGameStore()
const mapStore = useMapStore()
const eta = new Eta();

/**
 * Functions
 */
const initFleetAction = () => {
  router.push('/map/galaxy/')
  // clear map area selection
  mapStore.areaBoxTL = {x:0,y:0}
  mapStore.areaBoxRB = {x:0,y:0}
  shipActionStore.initFleet(fleetData.value)
}

const clearFleetAction = () => {
  const backend = new Backend();
  backend.setFleetActions(props.fleetId,[])
    .then(() => {
      gameStore.setTinySuccessToastMessage('Orders cleared')
    })
    .catch((error) => {
      gameStore.setTinyErrorToastMessage(error.response.data.message)
      throw error
    })
}

/**
 * Computed
 */
const fleetData = computed(() => fleetStore.findFleetById(props.fleetId)!)


// A computed property to calculate eta for each action
const calculateEtas = computed(() => {
  if (!fleetData.value || !fleetData.value.actions.length) return [];

  eta.start(fleetData.value?.xPos, fleetData.value?.yPos);

  return fleetData.value.actions.map((action: any) => {
    if (action.targetX !== undefined && action.targetY !== undefined) {
      // Use the Eta model to calculate the ETA for each action
      if(fleetData.value) {
        return eta.etaNext(action.targetX, action.targetY, fleetData.value.maxSpeed);
      }
    }
    return 0;
  });
});

</script>

<template>
  <StandardCard title="Action Queue" help="This is the list of actions your fleet will execute one after another." top-padding="2" bottom-padding="0"></StandardCard>
  <div class="flex pt-1">
    <div class="flex shrink-0">
      <div class="basis-12">
        <div @click="initFleetAction" class="bg-slate-800 hover:bg-slate-700 px-3 mb-1 py-2 cursor-pointer rounded"><GenericIcon type="cog"/></div>
        <div @click="clearFleetAction" class="bg-slate-800 hover:bg-slate-700 px-3 mb-1 py-2 cursor-pointer rounded"><GenericIcon type="trash"/></div>
      </div>
    </div>
    <div class="flex-grow ps-2">
      <div v-if="fleetData.actions.length !== 0">
        <div v-for="(action, index) in fleetData.actions" :key="index">
          <!-- Pass the specific ETA for each action from calculateEtas -->
          <ShipCurrentActions :shipActionData="action" :eta="calculateEtas[index]" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>