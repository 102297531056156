<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useAssetStore} from "@/stores/assetStore.ts";
import {computed} from "vue";
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { CDN } from '@/models/cdn.ts'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'

const assetStore = useAssetStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const buildingData = computed(() => assetStore.findBuildingByCode(props.newsItem.contentData.buildingCode));

</script>

<template>
  <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">
    New building on planet {{ props.newsItem.contentData.planetName }}
  </NewsItemHeader>
  <div class="bg-slate-800 p-2 text-subtext flex ">
    <div class="flex-shrink pr-2">
      <ModalTrigger type="building" :data="{'buildingCode': props.newsItem.contentData.buildingCode}" >
        <ImageRoundedUrl
          :url="CDN.building(128,props.newsItem.contentData.buildingCode)"
          :size="8"
        />
      </ModalTrigger>
    </div>
    <div class="flex-grow ps-1">
      <div class="pb-2">
        A <b>{{ buildingData.buildingName }}</b> has been constructed on planet <b>{{ props.newsItem.contentData.planetName }}</b>
      </div>
      <div class="text-subtext text-xs">{{ buildingData.buildingDescription }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>