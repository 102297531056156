<script setup lang="ts">

import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'

const props = defineProps({
  header: {
    type: String,
    required: true
  },
  subheader: {
    type: String,
    required: false,
    default: ''
  },
  amount: {
    type: Number,
    required: true
  },
  total: {
    type: Boolean,
    required: false,
    default: false
  },
  type: {
    type: String,
    required: false,
    default: ''
  }
})

const playerCreditStore = usePlayerCreditStore()

const openDetails = () => {
  if (props.type != '') {
    playerCreditStore.balanceSheetDetails = props.type
  }
}

</script>

<template>
  <StandardCard :top-padding="(total?'2':'1')">
  <div class="flex justify-between cursor-pointer"

  @click="openDetails"
  >
    <div class="ps-1">
      <div>{{ header }}</div>
      <small class="text-subtext">{{ subheader }}</small>
    </div>
    <div class="flex items-center pe-2 text-xl">
      <ColoredNumber :amount="amount" />
    </div>
  </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>