<script setup lang="ts">

import { computed, defineProps } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import Ore from '@/components/Sections/Resources/Elements/PlanetOre/Ore.vue'
import ConveyorTRBL from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTRBL.vue'
import ConveyorTLBR from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTLBR.vue'
import { CDN } from '@/models/cdn.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const props = defineProps({
  planetType: {
    type: Number,
    required: true
  },
  oreLeft: {
    type: Number,
    required: true
  },
  oreRight: {
    type: Number,
    required: true
  },
  conversionLeft: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conversionRight: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})


const planetTypeName = computed(() => {
  const planetType = assetStore.planetTypes.get(props.planetType)
  if(!planetType) {
    return ''
  }
  return planetType.name
})

const planetTypeColor = computed(() => {
  if(props.planetType === gameStore.player?.homeWorldPlanetType) {
    return 'text-green-500'
  } else if (props.planetType === gameStore.player?.homeWorldOppositePlanetType) {
    return 'text-red-500'
  } else {
    return 'text-white'
  }
})


</script>

<template>
  <div :class="{
          'absolute rounded':true,
          'border':debug
        }"
       :style="'top:' + props.top + 'rem;left:' + props.left + 'rem;width:15rem;height:15rem;'"
  >

    <!-- Planet -->
    <div :class="{
      'absolute text-center':true,
      [planetTypeColor]:true
    }" style="width:15rem;z-index:15">{{ planetTypeName }}</div>
    <img :class="{
          'absolute':true,
          'opacity-50':debug
        }" style="top:1.5rem;left:5.5rem;width:4rem;z-index:15" :src="gameStore.cdn + '/images/planet/new/128/planet_' + props.planetType + '.png'">

    <!-- Left Ore -->
    <Ore
      :ore-id="props.oreLeft"
      :top="8"
      :left="0"
      :debug="debug"
    />

    <ConveyorTRBL
      :object-image="CDN.icon('ore' + props.oreLeft)"
      :conversion="props.conversionLeft"
      :width="5"
      :height="7"
      :top="3"
      :left="2"
      :debug="debug"
    />

    <!-- Right Ore -->
    <Ore
      :ore-id="props.oreRight"
      :top="8"
      :left="10"
      :debug="debug"
    />

    <ConveyorTLBR
      :object-image="CDN.icon('ore' + props.oreRight)"
      :conversion="props.conversionRight"
      :width="5"
      :height="7"
      :top="3"
      :left="8"
      :debug="debug"
    />

<!--    <ResourceProcessedAmount :left="10" :top="1.9" textAlign="right" :conversion="props.conversionRight"/>-->
  </div>
</template>

<style scoped lang="scss">

</style>