<script setup lang="ts">

import ConveyorTLBR from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTLBR.vue'

import { defineProps, computed } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import ConveyorTRBL from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTRBL.vue'
import ConveyorTB from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTB.vue'
import Ore from '@/components/Sections/Resources/Elements/PlanetOre/Ore.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import { CDN } from '@/models/cdn.ts'

const gameStore = useGameStore()
const planetStore = usePlanetStore()

const props = defineProps({
  left: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  ore: {
    type: Number,
    required: true
  },
  processed: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conveyorType: {
    type: String,
    required: false,
    default: 'left'
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const terraformerCount = computed(() => {
  return planetStore.countPlanetsTerraForming()
})

</script>

<template>
<div :class="{
          'absolute':true,
          'border':debug
        }"
     :style="'top:' + top + 'rem;left:' + left + 'rem;width:' + width + 'rem;height:' + height + 'rem;'">

  <!-- Left -->
  <ConveyorTLBR
    v-if="conveyorType == 'right'"
    :left="1.25"
    :top="1"
    :height="18"
    :width="3"
    :midTime="0.15"
    :min-speed="5"
    :object-image="CDN.icon('ore' + ore)"
    :conversion="processed"
    :debug="debug"
  />

  <!-- Right -->
  <ConveyorTRBL
    v-else-if="conveyorType == 'left'"
    :left="3.5"
    :top="1"
    :height="18"
    :width="4"
    :midTime="0.15"
    :min-speed="5"
    :object-image="CDN.icon('ore' + props.ore)"
    :conversion="processed"
    :debug="debug"
  />



  <div :class="{
            'absolute rounded bg-slate-800 border border-slate-700 hover:border-slate-600':true,
            'opacity-50':debug
          }"
       style="top:18rem;left:0rem;width:8rem;z-index:15"
  >
    <div class="flex-col pb-1">
      <img
        :class="{
          'mx-auto p-1' : true,
          'grayscale': terraformerCount == 0
        }"
        :src="CDN.building(128,'terraforming_command_center')">
      <div class="text-center text-green-500" v-if="terraformerCount > 0">{{ terraformerCount }} Planets Terraforming</div>
      <div class="text-center" v-else>Terraforming</div>
    </div>
  </div>

</div>
</template>

<style scoped lang="scss">

</style>