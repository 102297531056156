<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true,
  },
  placeHolder: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '20',
  }
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (value) => {
  emit('update:modelValue', value);
};

</script>

<template>
  <div class="flex">
    <div v-if="name!=''" class="bg-slate-800 text-slate-300 flex items-center px-2 pt-0 mt-0 rounded-s">{{ name }}</div>
    <div class="bg-slate-800 text-slate-300 px-2 flex items-center rounded-e">
      <input
        type="text"
        :value="modelValue"
        :placeholder="placeHolder"
        @input="updateValue($event.target.value)"
        :class="'w-' + width"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
input {
  @apply bg-slate-700 p-1 px-2 text-slate-300 focus:outline-none rounded
}
</style>