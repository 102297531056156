<script setup lang="ts">

import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { computed, ref, watch } from 'vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import SignDesignComponentAvailable
  from '@/components/Interface/Modal/Modals/ModalCreateShipDesign/SignDesignComponentAvailable.vue'

/**
 * Stores / models
 */
const shipDesignStore = useShipDesignCreationStore()
const shipDesignCreationStore = useShipDesignCreationStore()
const gameStore = useGameStore()
const assetStore = useAssetStore()

/**
 * Variables
 */
const selectedType = ref('all');



/**
 * functions
 */
const isAvailable = (componentCode: string) => {
  const component = assetStore.findShipComponentByCode(componentCode)
  // If there is no frame, the only show frames
  if(shipDesignStore.hasFrame) {
    if(component.group === 'frame') {
      return false
    }
    if(!shipDesignStore.hasEngine && component.group === 'engine') {
      return true
    }
    if(!shipDesignStore.hasEngine && component.group !== 'engine') {
      return false
    }
    const groups = ['scanner','special']
    if(groups.includes(component.group)) {
      for (const comp of shipDesignCreationStore.components) {
        if(comp.component.group === component.group) {
          return false;
        }
      }
      return true
    }
    return true
  } else {
    return component.group === 'frame'
  }
}

/**
 * Computed
 */
const list = computed(() => {
  if(!shipDesignStore.hasFrame) {
    return ['frame']
  }
  if(!shipDesignStore.hasEngine) {
    return ['engine']
  }
  return [
    'armor','engine','repair','scanner','shields',
    'weapon','special'
  ];
});

const sortedAvailable = computed(() => {
  console.log('Recalculating sortedAvailable:', selectedType.value);
  return [...gameStore.playerShipComponents.entries()]
    .filter(([key, code]) => {
      if (selectedType.value === 'all') return true;
      const component = assetStore.findShipComponentByCode(code);
      return component?.group === selectedType.value;
    })
    .sort((a, b) => {
      const aSortKey = assetStore.findShipComponentByCode(a[1])?.sortKey || '';
      const bSortKey = assetStore.findShipComponentByCode(b[1])?.sortKey || '';
      return aSortKey.localeCompare(bSortKey);
    });
});

/**
 * Watchers
 */
watch(() => list.value, (value) => {
  if(!shipDesignStore.hasFrame) {
    selectedType.value = 'all'
  }
  if(!shipDesignStore.hasEngine) {
    selectedType.value = 'all'
  }
});


</script>

<template>
  <div class="flex flex-between">
    <div class="flex-shrink-0 basis-12 me-1">
      <div
        @click="selectedType = 'all'"
        :class="{
            ' p-2 mb-1 ps-3 me-1 text-slate-300 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': selectedType === 'all',
            'bg-slate-800 hover:bg-slate-700': selectedType !== 'all'
          }">
        All
      </div>
      <template v-for="item in list">
        <div
          @click="selectedType = item"
          :class="{
            ' p-2 mb-1 ps-3 me-1 flex items-center justify-between cursor-pointer rounded':true,
            'bg-slate-700': selectedType === item,
            'bg-slate-800 hover:bg-slate-700': selectedType !== item
          }">
          <GenericIcon :type="item" :class="{'grayscale' : selectedType !== item}"/>
        </div>
      </template>
    </div>
    <div class="h-[calc(100vh-17.5rem)] overflow-y-auto md:h-[calc(100vh-14.5rem)]">
      <div class="flex-grow grid grid-cols-2 gap-2">
        <template v-for="(value, index) in sortedAvailable" :key="value[1]">
          <template v-if="isAvailable(value[1])">
            <div class="self-top">
              <SignDesignComponentAvailable :componentCode="value[1]" />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>