<script setup lang="ts">
import {nextTick, onMounted, ref} from 'vue';

const props = defineProps({
  modelValue: String,
  label: String,
  type: {
    type: String,
    default: 'text'
  },
  placeholder: String,
  error: String,
  inputClass: {
    type: String,
    default: 'design'
  }
});

const formFocus = ref();

onMounted(() => {
  //auto focus the input field
  nextTick().then(() => {
    formFocus.value.focus();
  });
})

</script>

<template>
  <div>
    <label class="block mb-2 text-subtext">{{ label }}</label>
    <input
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :class="inputClass"
      :placeholder="placeholder"
      ref="formFocus"
    />
    <div class="pt-1 pb-1 text-red-500" v-if="error">{{ error }}</div>
  </div>
</template>


<style scoped>
.design {
  @apply bg-slate-800 border border-transparent focus:border-transparent focus:outline-none text-slate-300 rounded focus:ring-slate-700 focus:border-slate-700 block w-full px-2 py-1.5;
}

</style>
