<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import Eta from '@/models/eta.ts'
import SingleMulti from '@/components/Objects/SingleMulti.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'

/**
 * Stores / models
 */
const gameStore = useGameStore()
const assetStore = useAssetStore()

/**
 * Computed
 */
const tech = computed(() => {
  return assetStore.findTechById(gameStore.player!.currentTechId)!
})

const techPercentage = computed(() => {
  if(!tech.value) {
    return 0;
  }
  const percentage = Math.floor((gameStore.player!.sciencePoints / tech.value?.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})

const eta = computed(() => {
  const eta = new Eta();
  const calculatedEta = eta.techETA(tech.value?.cost)
  return calculatedEta<0 ? 0 : calculatedEta;
})

</script>

<template>
  <StandardCard v-if="tech?.id" title="Currently Researching" bottom-padding="2">
    <ModalTrigger type="tech" :data="{'techId': tech.id}">
      <div class="rounded hidden sm:block">
        <ImageRoundedUrl
          :url="CDN.technology(256,tech.code)"
        />
      </div>
      <div class="text-center text-slate-300 p-2"> {{tech.name}}</div>
      <SimpleProgressBar color="blue" :percentage="techPercentage" />
      <div class="text-center text-slate-300 p-2">
        ETA : {{ eta }} <SingleMulti :value="eta" single="week" multi="weeks"/>
      </div>
    </ModalTrigger>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>