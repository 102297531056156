import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Init from "@/models/init";
import router from './router'
import PrimeVue from 'primevue/config';
import * as Sentry from "@sentry/vue";
import { version as appVersion } from '../package.json';
import { CDN } from '@/models/cdn.ts'

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: "https://41ab5a7116f743bee4b7bc6c072e4d2e@o1300993.ingest.us.sentry.io/4508050333958144",
    release: `ferion@${appVersion}`, // Dynamic version from package.json
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router)
app.use(PrimeVue);
app.use(createPinia())
app.mount('#app')

// Register the CDN object globally
app.config.globalProperties.CDN = CDN;

//Init the game
new Init();