<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import MediumPlusModel from '@/components/Interface/Modal/ModelSizes/MediumPlusModel.vue'
import ConfigPQAAvailable from '@/components/Interface/Modal/Modals/ModalConfigPQA/ConfigPQAAvailable.vue'
import { computed, onMounted, ref } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'
import ConfigPQASelected from '@/components/Interface/Modal/Modals/ModalConfigPQA/ConfigPQASelected.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()
const PQAStore = usePQAStore()

/**
 * Variables
 */
const type = ref('')
const list = [
  'food','production','science','credit','energy','energy_storage',
  'ore_storage','scanning_range','defence_attack_ships','defence_shields',
  'ore_refinery','ore'
];

/**
 * Computed
 */
const availableBuildings = computed(() => {
  let filtered: Map<any, any>;

  if (type.value === '') {
    filtered = assetStore.findAllBuildingProductionObjects();
  } else {
    filtered = assetStore.findBuildingProductionObjectsByEffectType(type.value);
  }

  // Sort by production cost
  return new Map([...filtered.entries()].sort((a, b) => a[1].productionCost - b[1].productionCost));
});


/**
 * Lifecycle
 */
onMounted(() => {
  PQAStore.selectQueue(gameStore.modalData)
})
</script>

<template>
  <MediumPlusModel title="Planet Production Automation">
    <div class="text-sm font-medium text-center border-b text-slate-400 border-slate-700 sm:hidden">
      <div class="overflow-x-auto whitespace-nowrap no-scrollbar">
        <ul class="flex">
          <li class="me-2">
            <a href="#"
               :class="{
                 'active': PQAStore.selectedModalMobileTab === 'queue',
                 'text-blue-500 border-blue-500': PQAStore.selectedModalMobileTab === 'queue',
                 'text-slate-500 border-transparent': PQAStore.selectedModalMobileTab !== 'queue',
                 'inline-block p-4 border-b-2 rounded-t-lg  hover:border-slate-300 hover:text-slate-300': true
             }"
               @click="PQAStore.selectedModalMobileTab = 'queue'">
              Queue
            </a>
          </li>
          <li class="me-2">
            <a href="#"
               :class="{
                 'active': PQAStore.selectedModalMobileTab === 'available',
                 'text-blue-500 border-blue-500': PQAStore.selectedModalMobileTab === 'available',
                 'text-slate-500 border-transparent': PQAStore.selectedModalMobileTab !== 'available',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
               @click="PQAStore.selectedModalMobileTab = 'available'">
              Available
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-between p-2">
      <div :class="{
        'pr-0 md:pr-2 w-full md:block': true,
        'hidden' : PQAStore.selectedModalMobileTab === 'available'
      }">
        <StandardCard title="Queue" help="Changes are saved automatically" top-padding="0" bottom-padding="0"></StandardCard>
        <div class="scroll-height">
          <template
            v-for="(productionObjectID,index) in PQAStore.selectedQueueData" :key="index"
          >
            <ConfigPQASelected
              :productionObjectId="productionObjectID"
              :PQAId="gameStore.modalData"
              :index="index"/>
          </template>
        </div>
      </div>
      <div :class="{
        'w-full md:block': true,
        'hidden' : PQAStore.selectedModalMobileTab === 'queue'
      }">
        <StandardCard title="Available" help="Select the buildings you want to add to your Building Queue." top-padding="0" bottom-padding="0"></StandardCard>
        <div class="flex">
          <div class="pt-1">
            <div
              @click="type = ''"
              :class="{
                  ' p-2 mb-1 px-3 me-1 flex items-center justify-center cursor-pointer text-slate-300 rounded':true,
                  'bg-slate-700': type === '',
                  'bg-slate-800': type !== ''
                }">
              All
            </div>
            <template v-for="item in list">
              <div
                @click="type = item"
                :class="{
                  ' p-2 mb-1 me-1 flex items-center justify-center cursor-pointer rounded':true,
                  'bg-slate-700': type === item,
                  'bg-slate-800': type !== item
                }">
                <GenericIcon :type="item" :class="{'slatescale' : type !== item}"/>
              </div>
            </template>
          </div>
        <div class="scroll-height w-full">
          <template
            v-for="productionObject in Array.from(availableBuildings.values())"
            :key="productionObject.id"
          >
            <ConfigPQAAvailable
              :productionObjectId="productionObject.id"
              :PQAId="gameStore.modalData"
            />
          </template>
        </div>
        </div>
      </div>
    </div>
  </MediumPlusModel>
</template>

<style scoped lang="scss">
.scroll-height {
  height: calc(100vh - 13rem);
  overflow-y: auto;
}
</style>