<script setup lang="ts">

import type Tech from '@/types/Tech.ts'
import { computed, onMounted, ref } from 'vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import Backend from '@/models/backend.ts'

/**
 * Stores / models
 */
const gameStore = useGameStore()
const assetStore = useAssetStore()

/**
 * Props
 */
const props = defineProps<{
  tech: Tech
}>();

/**
 * Variables
 */
const top = ref(0)
const left = ref(0)

/**
 * Functions
 */
const setTech = () => {
  const backend = new Backend();
  backend.setTechTarget(props.tech.id).then(() => {
    gameStore.setTinySuccessToastMessage('Target research tech set');
  });

}

/**
 * Computed
 */
const status = computed(() => {
  if(gameStore.player?.currentTechId === props.tech.id) {
    return 'researching'
  }
  if(gameStore.playerTechs.has(props.tech.id)) {
    return 'researched'
  }
  const targetTech = assetStore.findTechById(gameStore.player!.targetTechId)
  if (targetTech && targetTech.requirementTree) {
    for (const requirementTechId of targetTech.requirementTree) {
      if (requirementTechId === props.tech.id) {
        return 'queued'
      }
    }
  }
  return 'available'
});

const name = computed(() => {
  if(status.value === 'researching') {
    return '<div class="flex justify-between text-cyan-500"><div>' + props.tech.name + '</div><div class="mr-2"></div></div>'
  }
  if(status.value === 'researched') {
    return '<div class="flex justify-between text-green-500"><div>' + props.tech.name + '</div><div class="mr-2"></div>'
  }
  if(status.value === 'queued') {
    return '<div class="flex justify-between text-yellow-500"><div>' + props.tech.name + '</div><div class="mr-2"></div>'
  }
  return props.tech.name
})

const provideIcons = computed<string[]>(() => {
  const icons: string[] = [];

  for (const benefit of props.tech.provides) {
    if (benefit.type === 'buildings') {
      const effects = assetStore.getBuildingEffectsByCode(benefit.code) as any;
      for (const effect of effects) {
        if (effect.bonus > 0) {
          const type = CDN.normalize(effect.type);
          // Prevent duplicate icons
          if (!icons.includes(type)) {
            icons.push(type);
          }
        }
      }
    } else if (benefit.type === 'ship_components') {
      const effects = assetStore.getShipComponentEffectsByCode(benefit.code) as any
      for (const effect of effects) {
        if (effect.modifier > 0) {
          if (!['ship_mass', 'ship_production_cost', 'ore'].includes(effect.type)) {
            const type = CDN.normalize(effect.type);
            if (!icons.includes(type)) {
              icons.push(type);
            }
          }
        }
      }
    }
  }

  return icons;
});

const techPercentage = computed(() => {
  if(gameStore.player?.currentTechId != props.tech.id) {
    return -1;
  }
  const tech = assetStore.findTechById(Number(gameStore.player?.currentTechId))
  if(!tech) {
    return 0;
  }
  const percentage = Math.floor((Number(gameStore.player?.sciencePoints)/ tech?.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})


onMounted(() => {
  top.value = 3.5 + ((props.tech.treeRow-1) * 13)
  left.value = 3 + ((props.tech.treeColumn-1) * 30)
})

</script>

<template>
  <StandardCard :title="name" :style="'top:' + top + 'rem;left:' + left + 'rem'" :class="
  {
    'tech':true,
    'opacity-40 hover:opacity-100': status === 'researched'
  }">
    <ModalTrigger type="tech" :data="{'techId': props.tech.id}">
      <div class="flex">
        <img
          :src="CDN.techIcon(props.tech.code)"
          alt=""
          class="rounded shrink-0"
          style="width:5rem !important;height:5rem !important;"
        >
        <div class="ps-2 flex flex-col justify-between w-full">
            <div class="flex justify-end">
              <template v-for="icon in provideIcons">
                <GenericIcon :type="icon" class="ml-2"/>
              </template>
            </div>
            <div class="flex justify-end">
              <div v-if="techPercentage !== -1" class="w-full">
                <SimpleProgressBar  :percentage="techPercentage" color="blue" :text="techPercentage + '%'" :height="5"/>
              </div>
              <ActionButton v-else-if="!gameStore.playerTechs.has(props.tech.id)" @click.stop="setTech">
                <GenericIconWithNumber :amount="props.tech.cost" type="science" text-color="text-slate-300"/>
              </ActionButton>
            </div>
          </div>
      </div>
    </ModalTrigger>
  </StandardCard>
</template>

<style scoped lang="scss">

.tech {
  position: absolute;
  width:18rem;

}
</style>