<script setup lang="ts">

import {defineProps} from "vue";
import router from "@/router";
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

const props = defineProps<{
  x: number,
  y: number,
}>();

const gotoMap = () => {
  router.push('/map/galaxy/' + props.x + '/' + props.y);
}

</script>

<template>
    <GenericIcon type="location" :title="'' + props.x + ' x ' + props.y " class="cursor-pointer" @click="gotoMap"></GenericIcon>
</template>

<style scoped lang="scss">

</style>