<script setup lang="ts">

import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true
  },
  shortFormat: {
    type: Boolean,
    default: true
  }
});

</script>

<template>
  <div class="flex">
    <GenericIcon :type="props.type"/><ColoredNumber :amount="props.amount" :shortFormat="shortFormat" class="ps-2"/>
  </div>
</template>

<style scoped lang="scss">

</style>