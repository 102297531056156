<script setup lang="ts">
import { useGameStore } from '@/stores/gameStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import TinyModel from '@/components/Interface/Modal/ModelSizes/Type2/TinyModel2.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'

const gameStore = useGameStore()

const doAction = () => {
  gameStore.modal2Data.action()
  gameStore.modal2Type = ''
}

</script>

<template>
  <TinyModel :title="gameStore.modal2Data.title" style="z-index: 100">
    <div class="text-subtext p-2">
      {{ gameStore.modal2Data.message }}
      <div class="flex justify-between pt-3">
        <DeleteButton color="red" @click="gameStore.modal2Type=''">
          {{ gameStore.modal2Data.cancelText }}
        </DeleteButton>
        <ActionButton @click="doAction">
          {{ gameStore.modal2Data.actionText }}
        </ActionButton>
      </div>
    </div>
  </TinyModel>
</template>


<style scoped lang="scss">

</style>