<script setup lang="ts">
import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import GFBalanceSheetItem from '@/components/Sections/Government/Finances/Elements/GFBalanceSheetItem.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { watch, onMounted } from 'vue'

/**
 * Stores / models
 */
const playerCreditStore = usePlayerCreditStore()


</script>

<template>
<div>
  <StandardCard title="Balance Sheet" help="The Balance Sheet gives you an overview on what you spend your credit on." top-padding="0" bottom-padding="1"/>
  <GFBalanceSheetItem
    :amount="playerCreditStore.playerCreditBalanceSheet.planets"
    header="Planets"
    subheader="Income/Costs from tax,buildings, etc."
    type="planets"
  />
  <GFBalanceSheetItem
    :amount="playerCreditStore.playerCreditBalanceSheet.ministers"
    header="Ministers"
    subheader="Salaries for hired ministers"
    type="ministers"
  />
  <GFBalanceSheetItem
    :amount="playerCreditStore.playerCreditBalanceSheet.oreTrades"
    header="Ore Trade"
    subheader="Cost of trading ore (maximum)"
    type="oreTrades"
  />
  <GFBalanceSheetItem
    :amount="0"
    header="VIPS"
    subheader="Salaries for hired VIPs"
    type="vips"
  />
  <GFBalanceSheetItem
    :amount="0"
    header="Spies"
    subheader="Salaries for hired spies"
    type="spies"
  />
  <GFBalanceSheetItem
    :amount="0"
    header="Ship Maintenance"
    subheader="Cost of operating your ships"
    type="shipMaintenance"
  />
  <GFBalanceSheetItem
    :amount="playerCreditStore.playerCreditBalanceSheet.creditsPerTurn"
    header="Credits per Turn"
    subheader="(Estimated)"
    :total="true"
  />
</div>
</template>

<style scoped lang="scss">

</style>