import { defineStore } from 'pinia'
import type OreConversions from '@/types/OreConversions.ts'
import { useGameStore } from '@/stores/gameStore.ts'

export const useOreConversionStore = defineStore('oreConversionStore', {
    state: () => ({
        data: {
            planetLavaToBezantium: {
                capacity: 0,
                enabled: true,
                name: 'Lava Planet Excavation of Bezantium',
                type: 'excavation'
            },
            planetLavaToRadiusant: {
                capacity: 0,
                enabled: true,
                name: 'Lava Planet Excavation of Radiusant',
                type: 'excavation'
            },
            planetDesertToNucloid: {
                capacity: 0,
                enabled: true,
                name: 'Desert Planet Excavation of Nucloid',
                type: 'excavation'
            },
            planetDesertToVerrotan: {
                capacity: 0,
                enabled: true,
                name: 'Desert Planet Excavation of Verrotan',
                type: 'excavation'
            },
            planetTemperateToBoturkoec: {
                capacity: 0,
                enabled: true,
                name: 'Temperate Planet Excavation of Boturkoec',
                type: 'excavation'
            },
            planetTemperateToNagazal: {
                capacity: 0,
                enabled: true,
                name: 'Temperate Planet Excavation of Nagazal',
                type: 'excavation'
            },
            planetOceanicToFormets: {
                capacity: 0,
                enabled: true,
                name: 'Oceanic Planet Excavation of Formets',
                type: 'excavation'
            },
            planetOceanicToGrindazine: {
                capacity: 0,
                enabled: true,
                name: 'Oceanic Planet Excavation of Grindazine',
                type: 'excavation'
            },
            planetGasToPerunga: {
                capacity: 0,
                enabled: true,
                name: 'Gas Planet Excavation of Perunga',
                type: 'excavation'
            },
            planetGasToVeronium: {
                capacity: 0,
                enabled: true,
                name: 'Gas Planet Excavation of Veronium',
                type: 'excavation'
            },
            planetIceToBlazanter: {
                capacity: 0,
                enabled: true,
                name: 'Ice Planet Excavation of Blazanter',
                type: 'excavation'
            },
            planetIceToTerrilliod: {
                capacity: 0,
                enabled: true,
                name: 'Ice Planet Excavation of Terrilliod',
                type: 'excavation'
            },
            bezantiumTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Bezantium Import',
                type: 'import'
            },
            bezantiumTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Bezantium Export',
                type: 'export'
            },
            radiusantTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Radiusant Import',
                type: 'import'
            },
            radiusantTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Radiusant Export',
                type: 'export'
            },
            nucloidTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nucloid Import',
                type: 'import'
            },
            nucloidTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nucloid Export',
                type: 'export'
            },
            verrotanTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Verrotan Import',
                type: 'import'
            },
            verrotanTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Verrotan Export',
                type: 'export'
            },
            boturkoecTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Boturkoec Import',
                type: 'import'
            },
            boturkoecTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Boturkoec Export',
                type: 'export'
            },
            nagazalTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nagazal Import',
                type: 'import'
            },
            nagazalTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nagazal Export',
                type: 'export'
            },
            formetsTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Formets Import',
                type: 'import'
            },
            formetsTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Formets Export',
                type: 'export'
            },
            grindazineTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Grindazine Import',
                type: 'import'
            },
            grindazineTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Grindazine Export',
                type: 'export'
            },
            perungaTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Perunga Import',
                type: 'import'
            },
            perungaTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Perunga Export',
                type: 'export'
            },
            veroniumTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Veronium Import',
                type: 'import'
            },
            veroniumTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Veronium Export',
                type: 'export'
            },
            blazanterTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Blazanter Import',
                type: 'import'
            },
            blazanterTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Blazanter Export',
                type: 'export'
            },
            terrilliodTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Terrilliod Import',
                type: 'import'
            },
            terrilliodTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Terrilliod Export',
                type: 'export'
            },
            convertBezantiumToKarmasas: {
                capacity: 0,
                enabled: true,
                name: 'Processing Bezantium into Karmasas',
                type: 'conversion'
            },
            convertRadiusantToKarmasas: {
                capacity: 0,
                enabled: true,
                name: 'Processing Radiusant into Karmasas',
                type: 'conversion'
            },
            convertNucloidToNadzerbat: {
                capacity: 0,
                enabled: true,
                name: 'Processing Nucloid into Nadzerbat',
                type: 'conversion'
            },
            convertVerrotanToNadzerbat: {
                capacity: 0,
                enabled: true,
                name: 'Processing Verrotan into Nadzerbat',
                type: 'conversion'
            },
            convertBoturkoecToGalagores: {
                capacity: 0,
                enabled: true,
                name: 'Processing Boturkoec into Galagores',
                type: 'conversion'
            },
            convertNagazalToGalagores: {
                capacity: 0,
                enabled: true,
                name: 'Processing Nagazal into Galagores',
                type: 'conversion'
            },
            convertFormetsToMiout: {
                capacity: 0,
                enabled: true,
                name: 'Processing Formets into Miout',
                type: 'conversion'
            },
            convertGrindazineToMiout: {
                capacity: 0,
                enabled: true,
                name: 'Processing Grindazine into Miout',
                type: 'conversion'
            },
            convertPerungaToLdwarts: {
                capacity: 0,
                enabled: true,
                name: 'Processing Perunga into Ldwarts',
                type: 'conversion'
            },
            convertVeroniumToLdwarts: {
                capacity: 0,
                enabled: true,
                name: 'Processing Veronium into Ldwarts',
                type: 'conversion'
            },
            convertBlazanterToMankazor: {
                capacity: 0,
                enabled: true,
                name: 'Processing Blazanter into Mankazor',
                type: 'conversion'
            },
            convertTerrilliodToMankazor: {
                capacity: 0,
                enabled: true,
                name: 'Processing Terrilliod into Mankazor',
                type: 'conversion'
            },
            produceKarmasas: {
                capacity: 0,
                enabled: true,
                name: 'Produced Karmasas',
                type: 'production'
            },
            produceNadzerbat: {
                capacity: 0,
                enabled: true,
                name: 'Produced Nadzerbat',
                type: 'production'
            },
            produceGalagores: {
                capacity: 0,
                enabled: true,
                name: 'Produced Galagores',
                type: 'production'
            },
            produceMiout: {
                capacity: 0,
                enabled: true,
                name: 'Produced Miout',
                type: 'production'
            },
            produceLdwarts: {
                capacity: 0,
                enabled: true,
                name: 'Produced Ldwarts',
                type: 'production'
            },
            produceMankazor: {
                capacity: 0,
                enabled: true,
                name: 'Produced Mankazor',
                type: 'production'
            },
            karmasasTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Karmasas Import',
                type: 'import'
            },
            karmasasTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Karmasas Export',
                type: 'export'
            },
            nadzerbatTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nadzerbat Import',
                type: 'import'
            },
            nadzerbatTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Nadzerbat Export',
                type: 'export'
            },
            galagoresTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Galagores Import',
                type: 'import'
            },
            galagoresTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Galagores Export',
                type: 'export'
            },
            mioutTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Miout Import',
                type: 'import'
            },
            mioutTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Miout Export',
                type: 'export'
            },
            ldwartsTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Ldwarts Import',
                type: 'import'
            },
            ldwartsTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Ldwarts Export',
                type: 'export'
            },
            mankazorTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Mankazor Import',
                type: 'import'
            },
            mankazorTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Mankazor Export',
                type: 'export'
            },
            convertKarmasasToFerrosnr6: {
                capacity: 0,
                enabled: true,
                name: 'Processing Karmasas into Ferros nr 6',
                type: 'conversion'
            },
            convertNadzerbatToFerrosnr6: {
                capacity: 0,
                enabled: true,
                name: 'Processing Nadzerbat into Ferros nr 6',
                type: 'conversion'
            },
            convertGalagoresToSjampoo: {
                capacity: 0,
                enabled: true,
                name: 'Processing Galagores into Sjampoo',
                type: 'conversion'
            },
            convertMioutToSjampoo: {
                capacity: 0,
                enabled: true,
                name: 'Processing Miout into Sjampoo',
                type: 'conversion'
            },
            convertLdwartsToTexorrium: {
                capacity: 0,
                enabled: true,
                name: 'Processing Ldwarts into Texorrium',
                type: 'conversion'
            },
            convertMankazorToTexorrium: {
                capacity: 0,
                enabled: true,
                name: 'Processing Mankazor into Texorrium',
                type: 'conversion'
            },
            produceFerrosnr6: {
                capacity: 0,
                enabled: true,
                name: 'Produced Ferros nr 6',
                type: 'production'
            },
            produceSjampoo: {
                capacity: 0,
                enabled: true,
                name: 'Produced Sjampoo',
                type: 'production'
            },
            produceTexorrium: {
                capacity: 0,
                enabled: true,
                name: 'Produced Tex',
                type: 'production'
            },
            ferrosnr6TradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Ferros nr 6 Import',
                type: 'import'
            },
            ferrosnr6TradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Ferros nr 6 Export',
                type: 'export'
            },
            sjampooTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Sjampoo Import',
                type: 'import'
            },
            sjampooTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Sjampoo Export',
                type: 'export'
            },
            texorriumTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Texorrium Import',
                type: 'import'
            },
            texorriumTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Texorrium Export',
                type: 'export'
            },
            convertFerrosnr6ToKroketite: {
                capacity: 0,
                enabled: true,
                name: 'Processing Ferros nr 6 into Kroketite',
                type: 'conversion'
            },
            convertSjampooToKroketite: {
                capacity: 0,
                enabled: true,
                name: 'Processing Sjampoo into Kroketite',
                type: 'conversion'
            },
            convertSjampooToRabogen: {
                capacity: 0,
                enabled: true,
                name: 'Processing Sjampoo into Rabogen',
                type: 'conversion'
            },
            convertTexorriumToRabogen: {
                capacity: 0,
                enabled: true,
                name: 'Processing Texorrium into Rabogen',
                type: 'conversion'
            },
            produceKroketite: {
                capacity: 0,
                enabled: true,
                name: 'Produced Kroketite',
                type: 'production'
            },
            produceRabogen: {
                capacity: 0,
                enabled: true,
                name: 'Produced Rabogen',
                type: 'production'
            },
            kroketiteTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Kroketite Import',
                type: 'import'
            },
            kroketiteTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Kroketite Export',
                type: 'export'
            },
            rabogenTradeIn: {
                capacity: 0,
                enabled: true,
                name: 'Trade Rabogen Import',
                type: 'import'
            },
            rabogenTradeOut: {
                capacity: 0,
                enabled: true,
                name: 'Trade Rabogen Export',
                type: 'export'
            },
            convertKroketiteToDropium: {
                capacity: 0,
                enabled: true,
                name: 'Processing Kroketite into Dropium',
                type: 'conversion'
            },
            convertRabogenToDropium: {
                capacity: 0,
                enabled: true,
                name: 'Processing Rabogen into Dropium',
                type: 'conversion'
            },
            produceDropium: {
                capacity: 0,
                enabled: true,
                name: 'Produced Dropium',
                type: 'production'
            },
            terraForming: {
                capacity: 0,
                enabled: true,
                name: 'Terraforming',
                type: 'terraforming'
            },
            dysonSwarm: {
                capacity: 0,
                enabled: true,
                name: 'Building Dyson Swarm',
                type: 'dysonSwarm'
            }
        } as OreConversions
    }),
    getters: {
    },
    actions: {
        setValue(key: keyof typeof this.data, value: number) {
            this.data[key].capacity = value;
        },
        getLevel2OreVisualDataFromPlanetType(planetType: number): { ore: number, left: number, conveyorType: string } {
            if(planetType === 1) {
                return this.getOreVisualDataFromOreId(13);
            } else if(planetType === 2) {
                return this.getOreVisualDataFromOreId(14);
            } else if(planetType === 3) {
                return this.getOreVisualDataFromOreId(15);
            } else if(planetType === 4) {
                return this.getOreVisualDataFromOreId(16);
            } else if(planetType === 5) {
                return this.getOreVisualDataFromOreId(17);
            } else if(planetType === 6) {
                return this.getOreVisualDataFromOreId(18);
            }
            throw new Error('Invalid planet type');
        },
        getOreVisualDataFromOreId(ore:number): { ore: number, left: number, conveyorType: string } {
            //Level 2
            if(ore === 13) {
                return {
                    ore: 13,
                    left: 2,
                    conveyorType: 'left'
                }
            } else if(ore === 14) {
                return {
                    ore: 14,
                    left: 30,
                    conveyorType: 'right'
                }
            } else if(ore === 15) {
                return {
                    ore: 15,
                    left: 42,
                    conveyorType: 'left'
                }
            } else if(ore === 16) {
                return {
                    ore: 16,
                    left: 70,
                    conveyorType: 'right'
                }
            } else if(ore === 17) {
                return {
                    ore: 17,
                    left: 82,
                    conveyorType: 'left'
                }
            } else if(ore === 18) {
                return {
                    ore: 18,
                    left: 110,
                    conveyorType: 'right'
                }
            }
            //Level 3
            if(ore === 19) {
                return {
                    ore: 19,
                    left: 12,
                    conveyorType: 'left'
                }
            } else if (ore === 20) {
                return {
                    ore: 20,
                    left: 50,
                    conveyorType: 'left'
                }
            } else if (ore === 21) {
                return {
                    ore: 21,
                    left: 100,
                    conveyorType: 'right'
                }
            }
            throw new Error('Invalid ore type ' + ore);
        }
    }
})
