<script setup lang="ts">
import {defineProps, ref} from "vue";
import FleetActions from '@/components/Sections/Command/Fleets/FleetDetails/FleetActions.vue'
import ShipsList from '@/components/Sections/Command/Ships/ShipsList.vue'
import FleetDisband from '@/components/Sections/Command/Fleets/FleetDetails/FleetDisband.vue'

const props = defineProps({
  fleetId: {
    type: Number,
    required: true
  }
});

const currentTab = ref('actions')

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-slate-300 border-slate-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'actions',
               'text-blue-500 border-blue-500': currentTab === 'actions',
               'text-slate-500 border-transparent': currentTab !== 'actions',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
           }"
           @click="currentTab = 'actions'">
          Actions
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'ships',
               'text-blue-500 border-blue-500': currentTab === 'ships',
               'text-slate-500 border-transparent': currentTab !== 'ships',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
           }"
           @click="currentTab = 'ships'">
          Ships
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'disband',
               'text-red-500 border-red-500': currentTab === 'disband',
               'text-slate-500 border-transparent': currentTab !== 'disband',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
           }"
           @click="currentTab = 'disband'">
          Disband
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'ships'" class="content-height-limit-fleet-details pt-2 ">
    <ShipsList :withDetails="false" :lockedToFleetId="props.fleetId" class="mt-2"></ShipsList>
  </div>
  <div v-if="currentTab === 'actions'" class="content-height-limit-fleet-details overflow-y-auto">
    <FleetActions :fleetId="props.fleetId" />
  </div>
  <div v-if="currentTab === 'disband'" class="content-height-limit-fleet-details overflow-y-auto">
    <FleetDisband :fleetId="props.fleetId" />
  </div>
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
