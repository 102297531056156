<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'
import { defineProps } from 'vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

const planetStore = usePlanetStore()

/**
 * Props
 */
const props = defineProps({
  type: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true
  },
  oreId: {
    type: Number,
    required: false
  }
});

/**
 * Functions
 */
const setTab = () => {
  planetStore.planetStatsTab = props.type;
  planetStore.planetStatsTabNoData = props.amount === 0;
}

</script>

<template>
  <div :class="{
    'menu-item':true,
    'active' : planetStore.planetStatsTab === type
  }"
  @click="setTab">
    <template v-if="type === 'primary_ore' || type === 'secondary_ore'">
      <GenericIcon :type="'ore' + oreId"/>
    </template>
    <template v-else>
      <GenericIcon :type="type"/>
    </template>
      <ColoredNumber :amount="amount" :allowZero="true" class="ps-6"></ColoredNumber>
  </div>
</template>

<style scoped lang="scss">
.menu-item {
  @apply bg-slate-800 p-2 mb-1 ps-3 me-1 flex items-center justify-between cursor-pointer rounded hover:bg-slate-700;
}
.menu-item.active {
  @apply bg-slate-700;
}
</style>