<script setup lang="ts">

import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import OreTrade from '@/components/Sections/Resources/Elements/OreTrade.vue'

const oreConversionStore = useOreConversionStore()

const debug = false
</script>

<template>

  <!--- Level 1 --->

  <OreTrade
    :left="2.5"
    :top="19"
    :conversion-in="oreConversionStore.data.bezantiumTradeIn"
    :conversion-out="oreConversionStore.data.bezantiumTradeOut"
    :ore-id="1"
    :debug="debug"
  />

  <OreTrade
    :left="12.5"
    :top="19"
    :conversion-in="oreConversionStore.data.radiusantTradeIn"
    :conversion-out="oreConversionStore.data.radiusantTradeOut"
    :ore-id="2"
    :debug="debug"
  />

  <OreTrade
    :left="22.5"
    :top="19"
    :conversion-in="oreConversionStore.data.nucloidTradeIn"
    :conversion-out="oreConversionStore.data.nucloidTradeOut"
    :ore-id="3"
    :debug="debug"
  />

  <OreTrade
    :left="32.5"
    :top="19"
    :conversion-in="oreConversionStore.data.verrotanTradeIn"
    :conversion-out="oreConversionStore.data.verrotanTradeOut"
    :ore-id="4"
    :debug="debug"
  />

  <OreTrade
    :left="42.5"
    :top="19"
    :conversion-in="oreConversionStore.data.boturkoecTradeIn"
    :conversion-out="oreConversionStore.data.boturkoecTradeOut"
    :ore-id="5"
    :debug="debug"
  />

  <OreTrade
    :left="52.5"
    :top="19"
    :conversion-in="oreConversionStore.data.nagazalTradeIn"
    :conversion-out="oreConversionStore.data.nagazalTradeOut"
    :ore-id="6"
    :debug="debug"
  />

  <OreTrade
    :left="62.5"
    :top="19"
    :conversion-in="oreConversionStore.data.formetsTradeIn"
    :conversion-out="oreConversionStore.data.formetsTradeOut"
    :ore-id="7"
    :debug="debug"
  />

  <OreTrade
    :left="72.5"
    :top="19"
    :conversion-in="oreConversionStore.data.grindazineTradeIn"
    :conversion-out="oreConversionStore.data.grindazineTradeOut"
    :ore-id="8"
    :debug="debug"
  />

  <OreTrade
    :left="82.5"
    :top="19"
    :conversion-in="oreConversionStore.data.perungaTradeIn"
    :conversion-out="oreConversionStore.data.perungaTradeOut"
    :ore-id="9"
    :debug="debug"
  />

  <OreTrade
    :left="92.5"
    :top="19"
    :conversion-in="oreConversionStore.data.veroniumTradeIn"
    :conversion-out="oreConversionStore.data.veroniumTradeOut"
    :ore-id="10"
    :debug="debug"
  />

  <OreTrade
    :left="102.5"
    :top="19"
    :conversion-in="oreConversionStore.data.blazanterTradeIn"
    :conversion-out="oreConversionStore.data.blazanterTradeOut"
    :ore-id="11"
    :debug="debug"
  />

  <OreTrade
    :left="112.5"
    :top="19"
    :conversion-in="oreConversionStore.data.terrilliodTradeIn"
    :conversion-out="oreConversionStore.data.terrilliodTradeOut"
    :ore-id="12"
    :debug="debug"
  />

  <!--- Level 2 --->

  <OreTrade
    :left="7.5"
    :top="55"
    :conversion-in="oreConversionStore.data.karmasasTradeIn"
    :conversion-out="oreConversionStore.data.karmasasTradeOut"
    :ore-id="13"
    :debug="debug"
  />

  <OreTrade
    :left="27.5"
    :top="55"
    :conversion-in="oreConversionStore.data.nadzerbatTradeIn"
    :conversion-out="oreConversionStore.data.nadzerbatTradeOut"
    :ore-id="14"
    :debug="debug"
  />

  <OreTrade
    :left="47.5"
    :top="55"
    :conversion-in="oreConversionStore.data.galagoresTradeIn"
    :conversion-out="oreConversionStore.data.galagoresTradeOut"
    :ore-id="15"
    :debug="debug"
  />

  <OreTrade
    :left="67.5"
    :top="55"
    :conversion-in="oreConversionStore.data.mioutTradeIn"
    :conversion-out="oreConversionStore.data.mioutTradeOut"
    :ore-id="16"
    :debug="debug"
  />

  <OreTrade
    :left="87.5"
    :top="55"
    :conversion-in="oreConversionStore.data.ldwartsTradeIn"
    :conversion-out="oreConversionStore.data.ldwartsTradeOut"
    :ore-id="17"
    :debug="debug"
  />


  <OreTrade
    :left="107.5"
    :top="55"
    :conversion-in="oreConversionStore.data.mankazorTradeIn"
    :conversion-out="oreConversionStore.data.mankazorTradeOut"
    :ore-id="18"
    :debug="debug"
  />

  <!--- Level 3 --->

  <OreTrade
    :left="17.5"
    :top="91"
    :conversion-in="oreConversionStore.data.ferrosnr6TradeIn"
    :conversion-out="oreConversionStore.data.ferrosnr6TradeOut"
    :ore-id="19"
    :debug="debug"
  />

  <OreTrade
    :left="57.5"
    :top="91"
    :conversion-in="oreConversionStore.data.sjampooTradeIn"
    :conversion-out="oreConversionStore.data.sjampooTradeOut"
    :ore-id="20"
    :debug="debug"
  />

  <OreTrade
    :left="97.5"
    :top="91"
    :conversion-in="oreConversionStore.data.texorriumTradeIn"
    :conversion-out="oreConversionStore.data.texorriumTradeOut"
    :ore-id="21"
    :debug="debug"
  />

  <!--- Level 4 --->

  <OreTrade
    :left="37.5"
    :top="127"
    :conversion-in="oreConversionStore.data.kroketiteTradeIn"
    :conversion-out="oreConversionStore.data.kroketiteTradeOut"
    :ore-id="22"
    :debug="debug"
  />

  <OreTrade
    :left="77.5"
    :top="127"
    :conversion-in="oreConversionStore.data.rabogenTradeIn"
    :conversion-out="oreConversionStore.data.rabogenTradeOut"
    :ore-id="23"
    :debug="debug"
  />

</template>

<style scoped lang="scss">

</style>