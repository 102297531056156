<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import MapMain from '@/components/Sections/Map/MapMain.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const gameStore = useGameStore()

</script>

<template>
  <div class="content bg-slate-900 text-slate-300">
    <div ref="content" v-if="gameStore.websocketInitialSyncCompleted === true">
      <div v-show="route.name?.toString().startsWith('Map')">
        <MapMain />
      </div>
      <router-view v-slot="{ Component, route }">
          <component v-if="!route.name?.toString().startsWith('Map')" :is="Component" />
      </router-view>
    </div>
  </div>
</template>

<style scoped>
.content {
  overflow-y: auto;
  height: calc(100vh - 5rem - var(--chat-height));
}
</style>