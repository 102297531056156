<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import PlanetAnimation from '@/components/Elements/Animations/Planet/PlanetAnimation.vue'
import PlanetTemperateImage from '@/components/Elements/Images/Planet/Types/PlanetTemperateImage.vue'

const gameStore = useGameStore()

</script>

<template>
<div class="p-2 flex justify-between">
  <div class="w-1/2">
    <p>Welcome {{ gameStore.player?.nickName }}</p>
    <br>
    <p>You are currently on the development server of the Ferion reboot.<br><br>

      This game is in alpha, and has bugs and many features from the original missing.<br><br>

      Please report any bugs you find to the discord server.<br><br>
    </p>
  </div>
  <div class="w-1/2">
    <img :src="gameStore.player?.profileImage">
  </div>
</div>
</template>

<style scoped lang="scss">
</style>
