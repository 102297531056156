<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useMapStore } from '@/stores/mapStore.ts'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import type Fleet from '@/types/Fleet.ts'

const tableStore = useTableStore();
const mapStore = useMapStore();
const playerRelationsStore = usePlayerRelationStore();
const mapCoordinateStore = useMapCoordinateStore();
const gameStore = useGameStore();


//Load filtered fleet list
const filters = (fleets:Map<number, Fleet>) => {
  return Array.from(fleets.values())
    .filter(fleet => fleet.playerId !== gameStore.player?.id)
    .filter((fleet) => {
      // only show enemy fleets
      if(!playerRelationsStore.isEnemy(fleet.playerId)) {
        return true;
      }
    })
    .filter((fleet) => {
      if(tableStore.mapCoordsFleets.filters.selectedPlayer === '') {
        return true;
      }
      if(Number(tableStore.mapCoordsFleets.filters.selectedPlayer) === fleet.playerId) {
        return true;
      }
    });
}

const onClick = (id:number) => {
  const data = mapCoordinateStore.fleets.get(id);
  if(data) {
    mapStore.focusMap(data.xPos, data.yPos);
  }
};

</script>false

<template>
  <FerionDataTable
    :data="mapCoordinateStore.fleets"
    tableStoreName="mapCoordsFleets"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-9rem)]'"
    :click="onClick"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarFiller class="mr-1 rounded">
        <div class="py-3">
          &nbsp;
        </div>
      </ActionBarFiller>
      <ActionBarSelect name="Player" width="6" v-model="tableStore.mapCoordsFleets.filters.selectedPlayer">
        <option value="" selected>All</option>
        <template v-for="player in playerRelationsStore.relations.values()">
          <option v-if="!playerRelationsStore.isEnemy(player.playerId)" :value="player.playerId" :key="player.playerId">{{ player.name }}</option>
        </template>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="12" column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="rounded-tl">
        <span class="pl-2">Player</span>
      </HeaderTextFlex>
      <HeaderFixedWidthText width="6" column="eta" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center"  title="Distance from selected Ship/Fleet">
        Distance
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="6" column="ships" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center" class="rounded-tr">
        Ships
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="12">
        <div class="flex flex-col pl-2">
          <div><PlayerName :player-id="item.playerId"/></div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="6" align="center">
        {{ item.eta>0 && item.eta !== Infinity ?item.eta:'-' }}
      </CellFixedWidth>
      <CellFixedWidth width="6" align="center">
        {{ item.ships }}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>