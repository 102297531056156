import { defineStore } from 'pinia'
import { usePlanetStore } from '@/stores/planetStore.ts'
import type PlayerCreditBalanceSheet from '@/types/PlayerCreditBalanceSheet.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'

export const usePlayerCreditStore = defineStore('playerCreditStore', {
    state: () => ({
        playerCreditBalanceSheet: {} as PlayerCreditBalanceSheet,
        balanceSheetDetails: 'planets' as string,
    }),
    getters: {

    },
    actions: {
        calculateCreditsPerTurn() {
            const planetStore = usePlanetStore();
            const gameStore = useGameStore();
            const assetStore = useAssetStore();
            const playerOreStore = usePlayerOreStore();

            // reset the balance sheet
            this.playerCreditBalanceSheet = {
                planets: 0,
                ministers: 0,
                oreTrades: 0,
                creditsPerTurn: 0
            }

            // Calculate credits from planets
            this.playerCreditBalanceSheet.planets = planetStore.getTotalCreditOutput()

            // Calculate debits from minister salaries
            for(const minister of gameStore.playerMinisters.values()) {
                this.playerCreditBalanceSheet.ministers -= assetStore.findMinisterSalaryByCode(minister)
            }

            // Calculate debits from ore trades
            for(const trade of playerOreStore.tradeData.values()) {
                const ore = assetStore.findOreById(trade.fromOreId)
                this.playerCreditBalanceSheet.oreTrades += ore.tradeConversionCost * trade.quantity
            }
            // Round up
            this.playerCreditBalanceSheet.oreTrades = -Math.ceil(this.playerCreditBalanceSheet.oreTrades)

            // Add array of all values
            const credits = Object.values(this.playerCreditBalanceSheet).reduce((acc, val) => acc + val, 0)

            this.playerCreditBalanceSheet.creditsPerTurn = credits

            return credits;
        }
    }
})
