<script setup lang="ts">
import {useGameStore} from "@/stores/gameStore.ts";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import OreTradeSelection from '@/components/Interface/Modal/Models/OreTrade/OreTradeSelection.vue'
import LargeModel from '@/components/Interface/Modal/ModelSizes/LargeModel.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()

const ore = computed(() => {
  return assetStore.findOreById(gameStore.modalData)
})

const tradableOres = computed(() => {
  let list = assetStore.findOresByLevel(ore.value.level)
  // Remove the current ore from the list
  list = list.filter((listOre) => {
    return listOre.id !== ore.value.id
  })
  return list
})

</script>

<template>
  <LargeModel :title="'Trading ' + ore.oreName + ' at the Galactic Ore Exchange'">
    <div class="text-subtext p-2">
      You can trade {{ ore.oreName }} at the Galactic Exchange. Each ore is traded at a 1:1 ratio.
    </div>
    <div class="grid grid-cols-2 sm:grid-cols-6 gap-2 p-2 text-subtext">
      <template v-for="ore in tradableOres" :key="ore.id">
        <OreTradeSelection :ore="ore" :source-ore-id="gameStore.modalData"/>
      </template>
    </div>
    <div class="text-subtext p-2">
      Trading ore costs credits, each trade will be repeated every week until you cancel it. Costs are rounded up to the nearest whole number.
    </div>
  </LargeModel>
</template>


<style scoped lang="scss">

</style>