<script setup lang="ts">
import {computed, defineProps, ref} from "vue";
import {useShipStore} from "@/stores/shipStore.ts";
import ShipActions from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions.vue'

/**
 * Props
 */
const props = defineProps({
  shipId: {
    type: Number,
    required: true
  }
});

/**
 * Stores
 */
const shipStore = useShipStore()
const shipData = computed(() => shipStore.findShipById(props.shipId)!)

/**
 * Variables
 */
const currentTab = ref('actions')

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-slate-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'actions',
               'text-blue-500 border-blue-500': currentTab === 'actions',
               'text-gray-500 border-transparent': currentTab !== 'actions',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
           }"
           @click="currentTab = 'actions'">
          Actions
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'actions'" class="content-height-limit-ship-details">
    <ShipActions :shipId="shipData.id" />
  </div>
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
