<script setup lang="ts">

import { computed } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import { formatNumber } from '@/models/formatNumber.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const gameStore = useGameStore()

const number = computed(() => {
  return gameStore.player?.credits??0
});


</script>

<template>
  <StandardCard title="Current Balance" top-padding="0">
    <div class="flex justify-center bg-slate-800 py-5">
      <span :class="{
        'balance': true,
        'text-green-500': number >= 0,
        'text-red-500': number < 0
      }">{{ formatNumber(number) }}</span>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">
  .balance {
    font-size: 2rem;
    font-weight: 600;
  }
</style>