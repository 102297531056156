<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'

import ShipCurrentActions
  from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions/Current/ShipCurrentActions.vue'

import {useShipActionStore} from "@/stores/shipActionStore.ts";
import Backend from '@/models/backend.ts'
import router from "@/router";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Eta from '@/models/eta.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

/**
 * Props
 */
const props = defineProps({
  shipId: {
    type: Number,
    required: true
  }
});

/**
 * Stores
 */
const shipStore = useShipStore()
const shipActionStore = useShipActionStore()
const eta = new Eta();

/**
 * Variables
 */

/**
 * Functions
 */
// Function to initialize ship actions
const initShipAction = () => {
  router.push('/map/galaxy/')
  if(shipData.value) {
    shipActionStore.initShip(shipData.value)
  }
}

// Function to clear ship actions
const clearShipAction = () => {
  const backend = new Backend();
  backend.clearShipActions([shipData.value]);
}

/**
 * Computed
 */
const shipData = computed(() => shipStore.findShipById(props.shipId)!)

// A computed property to calculate eta for each action
const calculateEtas = computed(() => {
  if (!shipData.value || !shipData.value.actions.length) return [];

  eta.start(shipData.value?.xPos, shipData.value?.yPos);

  return shipData.value.actions.map((action: any) => {
    if (action.targetX !== undefined && action.targetY !== undefined) {
      // Use the Eta model to calculate the ETA for each action
      if(shipData.value) {
        return eta.etaNext(action.targetX, action.targetY, shipData.value.maxSpeed);
      }
    }
    return 0;
  });
});



</script>

<template>
  <StandardCard title="Action Queue" help="This is the list of actions your ship will execute one after another." top-padding="2" bottom-padding="0"></StandardCard>
  <div class="flex pt-1">
    <div class="flex shrink-0">
      <div class="basis-12">
        <div @click="initShipAction" class="bg-slate-800 hover:bg-slate-700 px-3 mb-1 py-2 cursor-pointer rounded"><GenericIcon type="cog"/></div>
        <div @click="clearShipAction" class="bg-slate-800  hover:bg-slate-700 px-3 mb-1 py-2 cursor-pointer rounded"><GenericIcon type="trash"/></div>
      </div>
    </div>
    <div class="flex-grow ps-2">
      <div v-if="shipData">
        <div v-for="(action, index) in shipData.actions" :key="index">
          <!-- Pass the specific ETA for each action from calculateEtas -->
          <ShipCurrentActions :shipActionData="action" :eta="calculateEtas[index]" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>